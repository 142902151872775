<div [id]="wrapperId" [class]="'s_accordion-wrap ' + wrapperClasses">
  @if(hasHeader) {
    <div [id]="headerId" [class]="headerClasses">
      <button [id]="id + '-toggle'" class="s_accordion-link link-button" [class.collapsed]="isCollapsed" (click)="toggle()">{{ name }}</button>
      @if(button1) {
        <button (click)="onButton1Click()" class="extra-button-1" [class]="button1Class" [style]="button1Style">{{ button1Label }}</button>
      }
      @if(additionalText) {
        <div [class]="additionalTextClass">{{ additionalText }}</div>
      }
      @if(additionalHtml) {
        <div [class]="additionalHtmlClass" [innerHTML]="additionalHtml | safeHtml"></div>
      }        
    </div>
  }

  <div [id]="contentId" class="s_sec-wrap" [collapse]="isCollapsed" [isAnimated]="true">
    <div class="s_sec-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
 