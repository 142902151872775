import { ActionCreator, createAction, props, Action } from '@ngrx/store';

import {
  ActionErrorBase,
  ClearObjectErrorActionInfo,
  DeleteMultipleObjectsActionFailInfo,
  DeleteMultipleObjectsActionInfo,
  DeleteMultipleObjectsActionSuccessInfo,
  DeleteObjectActionFailInfo,
  DeleteObjectActionInfo,
  DeleteObjectActionSuccessInfo,
  LoadObjectActionFailInfo,
  LoadObjectActionInfo,
  LoadObjectActionSuccessInfo,
  LoadObjectHistoryActionFailInfo,
  LoadObjectHistoryActionInfo,
  LoadObjectHistoryActionSuccessInfo,
  LoadObjectListActionFailInfo,
  LoadObjectListActionInfo,
  LoadObjectListActionSuccessInfo,
  MoveLoadedListToCurrentActionInfo,
  MoveLoadedToCurrentActionInfo,
  UpdateObjectListActionFailInfo,
  UpdateObjectListActionInfo,
  UpdateObjectListActionSuccessInfo,
  UpdateOrCreateActionFailInfo,
  UpdateOrCreateActionInfo,
  UpdateOrCreateActionSuccessInfo,
  UpdateOrCreateMultipleActionFailInfo,
  UpdateOrCreateMultipleActionInfo,
  UpdateOrCreateMultipleActionSuccessInfo
} from '../../models/object';
import { ObjectActionTypes } from './enums';
import { FileOperationInfo, FileOperationMoveMultipleFilesRequest, FileOperationRemoveMultipleFilesRequest, FileOperationRequest } from '@safarilaw-webapp/shared/common-objects-models';

enum ActionType {
  Success = 'Success',
  Fail = 'Fail'
}
/** List actions */
const createLoadObjectsForListAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<LoadObjectListActionInfo>());
};
const createLoadObjectsForListSuccessAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<LoadObjectListActionSuccessInfo<T>>());
};
const createLoadObjectsForListFailAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<LoadObjectListActionFailInfo>());
};
/** Object actions */
const createLoadObjectAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<LoadObjectActionInfo<T>>());
};
const createLoadObjectSuccessAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<LoadObjectActionSuccessInfo<T>>());
};
const createLoadObjectActionFailInfo = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<LoadObjectActionFailInfo>());
};
const createCreateOrUpdateObjectAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateActionInfo<T>>());
};
const createCreateObjectSuccessAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateActionSuccessInfo<T>>());
};
const createUpdateObjectSuccessAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateActionSuccessInfo<T>>());
};
const createCreateOrUpdateObjectFailAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateActionFailInfo<T>>());
};

const createCreateOrUpdateMultipleObjectsAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateMultipleActionInfo<T>>());
};
const createCreateMultipleObjectsSuccessAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateMultipleActionSuccessInfo<T>>());
};
const createUpdateMultipleObjectsSuccessAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateMultipleActionSuccessInfo<T>>());
};
const createCreateOrUpdateMultipleObjectFailAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateMultipleActionFailInfo<T>>());
};

const createUpdatePartialObjectAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateActionInfo<T>>());
};

const createUpdatePartialObjectSuccessAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateActionSuccessInfo<T>>());
};
const createUpdatePartialObjectFailAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateOrCreateActionFailInfo<T>>());
};
const createDeleteObjectAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<DeleteObjectActionInfo>());
};
const createDeleteObjectSuccessAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<DeleteObjectActionSuccessInfo>());
};
const createDeleteObjectFailAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<DeleteObjectActionFailInfo>());
};
const createDeleteMultipleObjectsAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<DeleteMultipleObjectsActionInfo<T>>());
};
const createDeleteMultipleObjectsSuccessAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<DeleteMultipleObjectsActionSuccessInfo<T>>());
};
const createDeleteMultipleObjectsFailAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<DeleteMultipleObjectsActionFailInfo<T>>());
};

const createClearObjectErrorAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<ClearObjectErrorActionInfo>());
};
const createClearStateAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type);
};
const createLoadObjectHistoryAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<LoadObjectHistoryActionInfo>());
};
const createLoadObjectHistorySuccessAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<LoadObjectHistoryActionSuccessInfo>());
};
const createLoadObjectHistoryFailAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<LoadObjectHistoryActionFailInfo>());
};
const createUploadObjectFileAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationRequest }>());
};
const createUploadObjectFileSuccessAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationInfo }>());
};
const createUploadObjectFileFailAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<ActionErrorBase>());
};
const createRemoveObjectFileAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationRequest }>());
};
const createRemoveObjectMultipleFilesAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationRemoveMultipleFilesRequest }>());
};
const createMoveObjectMultipleFilesAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationMoveMultipleFilesRequest }>());
};
const createMoveObjectMultipleFilesSuccessAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationInfo }>());
};
const createMoveObjectMultipleFilesFailAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<ActionErrorBase>());
};

const createRemoveObjectFileSuccessAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationInfo }>());
};
const createRemoveObjectMultipleFilesSuccessAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: FileOperationInfo }>());
};

const createRemoveObjectFileFailAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<ActionErrorBase>());
};
const createRemoveObjectMultipleFilesFailAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<ActionErrorBase>());
};
const createMoveLoadedToCurrentAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: MoveLoadedToCurrentActionInfo }>());
};
const createMoveLoadedListToCurrentAction = (type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<{ payload: MoveLoadedListToCurrentActionInfo }>());
};
const createUpdateObjectsForListAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateObjectListActionInfo<T>>());
};
const createUpdateObjectsForListSuccessAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateObjectListActionSuccessInfo<T>>());
};
const createUpdateObjectsForListFailAction = <T>(type: any) => {
  if (String.isNullOrEmpty(type)) {
    return null;
  }
  return createAction(type, props<UpdateObjectListActionFailInfo<T>>());
};

export class DefaultActionConverter<T> {
  constructor(
    private _actions: {
      loadObjectList?: ActionCreator<any, (props: LoadObjectListActionInfo) => LoadObjectListActionInfo & Action<string>>;
      loadObjectListSuccess?: ActionCreator<any, (props: LoadObjectListActionSuccessInfo<T>) => LoadObjectListActionSuccessInfo<T> & Action<string>>;
      loadObjectListFail?: ActionCreator<any, (props: LoadObjectListActionFailInfo) => LoadObjectListActionFailInfo & Action<string>>;
      loadObject?: ActionCreator<any, (props: LoadObjectActionInfo<T>) => LoadObjectActionInfo<T> & Action<string>>;
      loadObjectSuccess?: ActionCreator<any, (props: LoadObjectActionSuccessInfo<T>) => LoadObjectActionSuccessInfo<T> & Action<string>>;
      loadObjectFail?: ActionCreator<any, (props: LoadObjectActionFailInfo) => LoadObjectActionFailInfo & Action<string>>;
      createOrUpdateObject?: ActionCreator<any, (props: UpdateOrCreateActionInfo<T>) => UpdateOrCreateActionInfo<T> & Action<string>>;
      createObjectSuccess?: ActionCreator<any, (props: UpdateOrCreateActionSuccessInfo<T>) => UpdateOrCreateActionSuccessInfo<T> & Action<string>>;
      updateObjectSuccess?: ActionCreator<any, (props: UpdateOrCreateActionSuccessInfo<T>) => UpdateOrCreateActionSuccessInfo<T> & Action<string>>;
      createOrUpdateObjectFail?: ActionCreator<any, (props: ActionErrorBase<any>) => ActionErrorBase<any> & Action<string>>;

      createOrUpdateMultipleObjects?: ActionCreator<any, (props: UpdateOrCreateMultipleActionInfo<T>) => UpdateOrCreateMultipleActionInfo<T> & Action<string>>;
      createMultipleObjectsSuccess?: ActionCreator<any, (props: UpdateOrCreateMultipleActionSuccessInfo<T>) => UpdateOrCreateMultipleActionSuccessInfo<T> & Action<string>>;
      updateMultipleObjectsSuccess?: ActionCreator<any, (props: UpdateOrCreateMultipleActionSuccessInfo<T>) => UpdateOrCreateMultipleActionSuccessInfo<T> & Action<string>>;
      createOrUpdateMultipleObjectsFail?: ActionCreator<any, (props: UpdateOrCreateMultipleActionFailInfo<any>) => UpdateOrCreateMultipleActionFailInfo<any> & Action<string>>;

      updatePartialObject?: ActionCreator<any, (props: UpdateOrCreateActionInfo<T>) => UpdateOrCreateActionInfo<T> & Action<string>>;
      updatePartialObjectSuccess?: ActionCreator<any, (props: UpdateOrCreateActionSuccessInfo<T>) => UpdateOrCreateActionSuccessInfo<T> & Action<string>>;
      updatePartialObjectFail?: ActionCreator<any, (props: ActionErrorBase<any>) => ActionErrorBase<any> & Action<string>>;

      deleteObject?: ActionCreator<any, (props: DeleteObjectActionInfo) => DeleteObjectActionInfo & Action<string>>;
      deleteObjectSuccess?: ActionCreator<any, (props: DeleteObjectActionSuccessInfo) => DeleteObjectActionSuccessInfo & Action<string>>;
      deleteObjectFail?: ActionCreator<any, (props: DeleteObjectActionFailInfo) => DeleteObjectActionFailInfo & Action<string>>;

      deleteMultipleObject?: ActionCreator<any, (props: DeleteMultipleObjectsActionInfo<T>) => DeleteMultipleObjectsActionInfo<T> & Action<string>>;
      deleteMultipleObjectSuccess?: ActionCreator<any, (props: DeleteMultipleObjectsActionSuccessInfo<T>) => DeleteMultipleObjectsActionSuccessInfo<T> & Action<string>>;
      deleteMultipleObjectFail?: ActionCreator<any, (props: DeleteMultipleObjectsActionFailInfo<T>) => DeleteMultipleObjectsActionFailInfo<T> & Action<string>>;

      loadObjectHistory?: ActionCreator<any, (props: LoadObjectHistoryActionInfo) => LoadObjectHistoryActionInfo & Action<string>>;
      loadObjectHistorySuccess?: ActionCreator<any, (props: LoadObjectHistoryActionSuccessInfo) => LoadObjectHistoryActionSuccessInfo & Action<string>>;
      loadObjectHistoryFail?: ActionCreator<any, (props: LoadObjectHistoryActionFailInfo) => LoadObjectHistoryActionFailInfo & Action<string>>;
      clearObjectError?: ActionCreator<any, (props: ClearObjectErrorActionInfo) => ClearObjectErrorActionInfo & Action<string>>;
      uploadFile?: ActionCreator<any, (props: { payload: FileOperationRequest }) => { payload: FileOperationRequest } & Action<string>>;
      uploadFileSuccess?: ActionCreator<any, (props: { payload: FileOperationInfo }) => { payload: FileOperationInfo } & Action<string>>;
      uploadFileFail?: ActionCreator<any, (props: ActionErrorBase) => ActionErrorBase & Action<string>>;
      removeFile?: ActionCreator<any, (props: { payload: FileOperationRequest }) => { payload: FileOperationRequest } & Action<string>>;
      removeFileSuccess?: ActionCreator<any, (props: { payload: FileOperationInfo }) => { payload: FileOperationInfo } & Action<string>>;
      removeFileFail?: ActionCreator<any, (props: ActionErrorBase) => ActionErrorBase & Action<string>>;
      moveMultipleFiles?: ActionCreator<any, (props: { payload: FileOperationMoveMultipleFilesRequest }) => { payload: FileOperationRequest } & Action<string>>;
      moveMultipleFilesSuccess?: ActionCreator<any, (props: { payload: FileOperationInfo }) => { payload: FileOperationInfo } & Action<string>>;
      moveMultipleFilesFail?: ActionCreator<any, (props: ActionErrorBase) => ActionErrorBase & Action<string>>;

      removeMultipleFiles?: ActionCreator<any, (props: { payload: FileOperationRemoveMultipleFilesRequest }) => { payload: FileOperationRequest } & Action<string>>;
      removeMultipleFilesSuccess?: ActionCreator<any, (props: { payload: FileOperationInfo }) => { payload: FileOperationInfo } & Action<string>>;
      removeMultipleFilesFail?: ActionCreator<any, (props: ActionErrorBase) => ActionErrorBase & Action<string>>;

      moveLoadedToCurrent?: ActionCreator<any, (props: { payload: MoveLoadedToCurrentActionInfo }) => { payload: MoveLoadedToCurrentActionInfo } & Action<string>>;
      moveLoadedListToCurrent?: ActionCreator<any, (props: { payload: MoveLoadedListToCurrentActionInfo }) => { payload: MoveLoadedListToCurrentActionInfo } & Action<string>>;
      updateObjectList?: ActionCreator<any, (props: UpdateObjectListActionInfo<T>) => UpdateObjectListActionInfo<T> & Action<string>>;
      updateObjectListSuccess?: ActionCreator<any, (props: UpdateObjectListActionSuccessInfo<T>) => UpdateObjectListActionSuccessInfo<T> & Action<string>>;
      updateObjectListFail?: ActionCreator<any, (props: UpdateObjectListActionFailInfo<T>) => UpdateObjectListActionFailInfo<T> & Action<string>>;
      clearState?: ActionCreator<any, () => Action<string>>;
    }
  ) {}
  get loadObjectList() {
    return this._actions.loadObjectList;
  }
  get loadObjectListSuccess() {
    return this._actions.loadObjectListSuccess;
  }
  get loadObjectListFail() {
    return this._actions.loadObjectListFail;
  }
  get loadObject() {
    return this._actions.loadObject;
  }
  get loadObjectSuccess() {
    return this._actions.loadObjectSuccess;
  }
  get loadObjectFail() {
    return this._actions.loadObjectFail;
  }
  get createOrUpdateObject() {
    return this._actions.createOrUpdateObject;
  }
  get createObjectSuccess() {
    return this._actions.createObjectSuccess;
  }
  get updateObjectSuccess() {
    return this._actions.updateObjectSuccess;
  }
  get createOrUpdateObjectFail() {
    return this._actions.createOrUpdateObjectFail;
  }
  get createOrUpdateMultipleObjects() {
    return this._actions.createOrUpdateMultipleObjects;
  }
  get createMultipleObjectsSuccess() {
    return this._actions.createMultipleObjectsSuccess;
  }
  get updateMultipleObjectsSuccess() {
    return this._actions.updateMultipleObjectsSuccess;
  }
  get createOrUpdateMulitpleObjectsFail() {
    return this._actions.createOrUpdateMultipleObjectsFail;
  }

  get updatePartialObject() {
    return this._actions.updatePartialObject;
  }

  get updatePartialObjectSuccess() {
    return this._actions.updatePartialObjectSuccess;
  }
  get updatePartialObjectFail() {
    return this._actions.updatePartialObjectFail;
  }
  get deleteObject() {
    return this._actions.deleteObject;
  }
  get deleteObjectSuccess() {
    return this._actions.deleteObjectSuccess;
  }
  get deleteObjectFail() {
    return this._actions.deleteObjectFail;
  }
  get deleteMultipleObjects() {
    return this._actions.deleteMultipleObject;
  }
  get deleteMultipleObjectsSuccess() {
    return this._actions.deleteMultipleObjectSuccess;
  }
  get deleteMultipleObjectsFail() {
    return this._actions.deleteMultipleObjectFail;
  }
  get loadObjectHistory() {
    return this._actions.loadObjectHistory;
  }
  get loadObjectHistorySuccess() {
    return this._actions.loadObjectHistorySuccess;
  }
  get loadObjectHistoryFail() {
    return this._actions.loadObjectHistoryFail;
  }
  get clearObjectError() {
    return this._actions.clearObjectError;
  }
  get uploadFile() {
    return this._actions.uploadFile;
  }
  get uploadFileSuccess() {
    return this._actions.uploadFileSuccess;
  }
  get uploadFileFail() {
    return this._actions.uploadFileFail;
  }
  get removeFile() {
    return this._actions.removeFile;
  }
  get removeFileSuccess() {
    return this._actions.removeFileSuccess;
  }
  get removeFileFail() {
    return this._actions.removeFileFail;
  }
  get moveMultipleFiles() {
    return this._actions.moveMultipleFiles;
  }
  get moveMultipleFilesSuccess() {
    return this._actions.moveMultipleFilesSuccess;
  }
  get moveMultipleFilesFail() {
    return this._actions.moveMultipleFilesFail;
  }
  get removeMultipleFiles() {
    return this._actions.removeMultipleFiles;
  }

  get removeMultipleFilesSuccess() {
    return this._actions.removeMultipleFilesSuccess;
  }
  get removeMultipleFilesFail() {
    return this._actions.removeMultipleFilesFail;
  }
  get moveLoadedToCurrent() {
    return this._actions.moveLoadedToCurrent;
  }
  get moveLoadedListToCurrent() {
    return this._actions.moveLoadedListToCurrent;
  }
  get updateObjectList() {
    return this._actions.updateObjectList;
  }
  get updateObjectListSuccess() {
    return this._actions.updateObjectListSuccess;
  }
  get updateObjectListFail() {
    return this._actions.updateObjectListFail;
  }
  get clearState() {
    return this._actions.clearState;
  }
}
export const populateDefaultActions = <T>(actionMap: Map<number, string>) =>
  new DefaultActionConverter<T>({
    loadObjectList: createLoadObjectsForListAction(actionMap.get(ObjectActionTypes.LoadObjectList)),
    loadObjectListSuccess: createLoadObjectsForListSuccessAction<T>(getSuccessFailType(actionMap, ObjectActionTypes.LoadObjectListSuccess, ObjectActionTypes.LoadObjectList, ActionType.Success)),
    loadObjectListFail: createLoadObjectsForListFailAction(getSuccessFailType(actionMap, ObjectActionTypes.LoadObjectListFail, ObjectActionTypes.LoadObjectList, ActionType.Fail)),
    loadObject: createLoadObjectAction<T>(actionMap.get(ObjectActionTypes.LoadObject)),
    loadObjectSuccess: createLoadObjectSuccessAction<T>(getSuccessFailType(actionMap, ObjectActionTypes.LoadObjectSuccess, ObjectActionTypes.LoadObject, ActionType.Success)),
    loadObjectFail: createLoadObjectActionFailInfo(getSuccessFailType(actionMap, ObjectActionTypes.LoadObjectFail, ObjectActionTypes.LoadObject, ActionType.Fail)),
    createOrUpdateObject: createCreateOrUpdateObjectAction<T>(actionMap.get(ObjectActionTypes.CreateOrUpdateObject)),
    createObjectSuccess: createCreateObjectSuccessAction<T>(
      getSuccessFailType(actionMap, ObjectActionTypes.CreateObjectSuccess, ObjectActionTypes.CreateOrUpdateObject, ActionType.Success).replace('OrUpdate', '')
    ),
    updateObjectSuccess: createUpdateObjectSuccessAction<T>(
      getSuccessFailType(actionMap, ObjectActionTypes.UpdateObjectSuccess, ObjectActionTypes.CreateOrUpdateObject, ActionType.Success).replace('CreateOr', '')
    ),
    createOrUpdateObjectFail: createCreateOrUpdateObjectFailAction<T>(
      getSuccessFailType(actionMap, ObjectActionTypes.CreateOrUpdateObjectFail, ObjectActionTypes.CreateOrUpdateObject, ActionType.Fail)
    ),

    createOrUpdateMultipleObjects: createCreateOrUpdateMultipleObjectsAction<T>(actionMap.get(ObjectActionTypes.CreateOrUpdateMultipleObjects)),
    createMultipleObjectsSuccess: createCreateMultipleObjectsSuccessAction<T>(
      getSuccessFailType(actionMap, ObjectActionTypes.CreateMultipleObjectsSuccess, ObjectActionTypes.CreateOrUpdateMultipleObjects, ActionType.Success).replace('OrUpdate', '')
    ),
    updateMultipleObjectsSuccess: createUpdateMultipleObjectsSuccessAction<T>(
      getSuccessFailType(actionMap, ObjectActionTypes.UpdateMultipleObjectsSuccess, ObjectActionTypes.CreateOrUpdateMultipleObjects, ActionType.Success).replace('CreateOr', '')
    ),
    createOrUpdateMultipleObjectsFail: createCreateOrUpdateMultipleObjectFailAction<T>(
      getSuccessFailType(actionMap, ObjectActionTypes.CreateOrUpdateMultipleObjectsFail, ObjectActionTypes.CreateOrUpdateMultipleObjects, ActionType.Fail)
    ),

    updatePartialObject: createUpdatePartialObjectAction<T>(actionMap.get(ObjectActionTypes.UpdatePartialObject)),

    updatePartialObjectSuccess: createUpdatePartialObjectSuccessAction<T>(
      getSuccessFailType(actionMap, ObjectActionTypes.UpdatePartialObjectSuccess, ObjectActionTypes.UpdatePartialObject, ActionType.Success).replace('CreateOr', '')
    ),
    updatePartialObjectFail: createUpdatePartialObjectFailAction<T>(getSuccessFailType(actionMap, ObjectActionTypes.UpdatePartialObjectFail, ObjectActionTypes.UpdatePartialObject, ActionType.Fail)),
    deleteObject: createDeleteObjectAction(actionMap.get(ObjectActionTypes.DeleteObject)),
    deleteObjectSuccess: createDeleteObjectSuccessAction(getSuccessFailType(actionMap, ObjectActionTypes.DeleteObjectSuccess, ObjectActionTypes.DeleteObject, ActionType.Success)),
    deleteObjectFail: createDeleteObjectFailAction(getSuccessFailType(actionMap, ObjectActionTypes.DeleteObjectFail, ObjectActionTypes.DeleteObject, ActionType.Fail)),

    deleteMultipleObject: createDeleteMultipleObjectsAction(actionMap.get(ObjectActionTypes.DeleteMultipleObjects)),
    deleteMultipleObjectSuccess: createDeleteMultipleObjectsSuccessAction(
      getSuccessFailType(actionMap, ObjectActionTypes.DeleteMultipleObjectsSuccess, ObjectActionTypes.DeleteMultipleObjects, ActionType.Success)
    ),
    deleteMultipleObjectFail: createDeleteMultipleObjectsFailAction(
      getSuccessFailType(actionMap, ObjectActionTypes.DeleteMultipleObjectsFail, ObjectActionTypes.DeleteMultipleObjects, ActionType.Fail)
    ),

    loadObjectHistory: createLoadObjectHistoryAction(actionMap.get(ObjectActionTypes.LoadObjectHistory)),
    loadObjectHistorySuccess: createLoadObjectHistorySuccessAction(getSuccessFailType(actionMap, ObjectActionTypes.LoadObjectHistorySuccess, ObjectActionTypes.LoadObjectHistory, ActionType.Success)),
    loadObjectHistoryFail: createLoadObjectHistoryFailAction(getSuccessFailType(actionMap, ObjectActionTypes.LoadObjectHistoryFail, ObjectActionTypes.LoadObjectHistory, ActionType.Fail)),
    clearObjectError: createClearObjectErrorAction(actionMap.get(ObjectActionTypes.ClearObjectError)),
    uploadFile: createUploadObjectFileAction(actionMap.get(ObjectActionTypes.UploadFile)),
    uploadFileSuccess: createUploadObjectFileSuccessAction(getSuccessFailType(actionMap, ObjectActionTypes.UploadFileSuccess, ObjectActionTypes.UploadFile, ActionType.Success)),
    uploadFileFail: createUploadObjectFileFailAction(getSuccessFailType(actionMap, ObjectActionTypes.UploadFileFail, ObjectActionTypes.UploadFile, ActionType.Fail)),

    removeFile: createRemoveObjectFileAction(actionMap.get(ObjectActionTypes.RemoveFile)),
    removeFileSuccess: createRemoveObjectFileSuccessAction(getSuccessFailType(actionMap, ObjectActionTypes.RemoveFileSuccess, ObjectActionTypes.RemoveFile, ActionType.Success)),
    removeFileFail: createRemoveObjectFileFailAction(getSuccessFailType(actionMap, ObjectActionTypes.RemoveFileFail, ObjectActionTypes.RemoveFile, ActionType.Fail)),

    moveMultipleFiles: createMoveObjectMultipleFilesAction(actionMap.get(ObjectActionTypes.MoveMultipleFiles)),
    moveMultipleFilesSuccess: createMoveObjectMultipleFilesSuccessAction(
      getSuccessFailType(actionMap, ObjectActionTypes.MoveMultipleFilesSuccess, ObjectActionTypes.MoveMultipleFiles, ActionType.Success)
    ),
    moveMultipleFilesFail: createMoveObjectMultipleFilesFailAction(getSuccessFailType(actionMap, ObjectActionTypes.MoveMultipleFilesFail, ObjectActionTypes.MoveMultipleFiles, ActionType.Fail)),
    removeMultipleFiles: createRemoveObjectMultipleFilesAction(actionMap.get(ObjectActionTypes.RemoveMultipleFiles)),
    removeMultipleFilesSuccess: createRemoveObjectMultipleFilesSuccessAction(
      getSuccessFailType(actionMap, ObjectActionTypes.RemoveMultipleFilesSuccess, ObjectActionTypes.RemoveMultipleFiles, ActionType.Success)
    ),
    removeMultipleFilesFail: createRemoveObjectMultipleFilesFailAction(
      getSuccessFailType(actionMap, ObjectActionTypes.RemoveMultipleFilesFail, ObjectActionTypes.RemoveMultipleFiles, ActionType.Fail)
    ),

    moveLoadedToCurrent: createMoveLoadedToCurrentAction(actionMap.get(ObjectActionTypes.SwapCurrentWithRefreshed)),
    moveLoadedListToCurrent: createMoveLoadedListToCurrentAction(actionMap.get(ObjectActionTypes.SwapCurrentListWithRefreshed)),
    updateObjectList: createUpdateObjectsForListAction<T>(actionMap.get(ObjectActionTypes.UpdateObjectList)),
    updateObjectListSuccess: createUpdateObjectsForListSuccessAction<T>(
      getSuccessFailType(actionMap, ObjectActionTypes.UpdateObjectListSuccess, ObjectActionTypes.UpdateObjectList, ActionType.Success)
    ),
    updateObjectListFail: createUpdateObjectsForListFailAction<T>(getSuccessFailType(actionMap, ObjectActionTypes.UpdateObjectListFail, ObjectActionTypes.UpdateObjectList, ActionType.Fail)),
    clearState: createClearStateAction(actionMap.get(ObjectActionTypes.ClearState))
  });

const getSuccessFailType = (actionMap: Map<number, string>, actualAction: ObjectActionTypes, defaultAction: ObjectActionTypes, actionType: ActionType) => {
  // If both the actual action enum (such as LoadObjectsSuccess for example) AND the default action this is responding to (LoadObject for example)
  // are null then return null, nothing we can do here
  if (String.isNullOrEmpty(actionMap.get(defaultAction)) && String.isNullOrEmpty(actionMap.get(actualAction))) {
    return '';
  }
  // If default action is not defined but success/fail is throw an error, since that makes no sense
  if (String.isNullOrEmpty(actionMap.get(defaultAction)) && !String.isNullOrEmpty(actionMap.get(actualAction))) {
    throw new Error('Success/Fail action defined but the default action is not!');
  }
  // If we defined the exact name for success/fail action in our enums, then pass that
  if (!String.isNullOrEmpty(actionMap.get(actualAction))) {
    return actionMap.get(actualAction);
  }

  // Otherwise we'll automatically generate success or fail action name
  return actionMap.get(defaultAction) + ' ' + actionType.toString();
};
