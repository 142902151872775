import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppDialogUiReduxObject, ConfirmationDialogButton, ConfirmationDialogComponent } from '@safarilaw-webapp/shared/dialog';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ErrorComponent } from '../components/error/error.component';
import { SettingsOverrideComponent } from '../components/settings-override';

enum Tabs {
  FeatureFlagsAndSettings,
  Errors
}
@Component({
  selector: 'devtools-confirmation-dialog',
  templateUrl: './devtools-dialog.component.html',
  styleUrls: ['./devtools-dialog.component.scss'],
  imports: [SettingsOverrideComponent, ErrorComponent]
})
export class DevtoolsDialogComponent extends ConfirmationDialogComponent {
  static ClassId = 'DevtoolsConfirmationDialogComponent_3b26a9d8-e16f-4e25-8fee-29a6d579c3c7';
  @ViewChild(SettingsOverrideComponent) settingsOverrideComponent: SettingsOverrideComponent;
  @ViewChild(ErrorComponent) errorComponent: ErrorComponent;
  Tabs = Tabs;
  currentTab = Tabs.FeatureFlagsAndSettings;
  ConfirmationDialogButton = ConfirmationDialogButton;

  form: FormGroup;
  constructor(
    dialogReduxObject: AppDialogUiReduxObject,
    bsModalService: BsModalService,
    store: Store<any>,
    actions: Actions,
    bsModalRef: BsModalRef,
    private _formBuilder: FormBuilder
  ) {
    super(dialogReduxObject, bsModalService, store, actions, bsModalRef);
    // this.form = this._formBuilder.group({
    //   matterSubtypeId: [null, Validators.required],
    //   matterSubtypePlaceholder: [null],
    //   matterSubtypeName: [null]
    // });
  }
  changeTab(tab: Tabs) {
    this.currentTab = tab;
  }
  getTabClass(tab: Tabs) {
    return this.currentTab == tab ? 'btn-dark' : 'btn-light';
  }

  get hasChanges() {
    return this.errorComponent?.hasChanges || this.settingsOverrideComponent?.hasChanges;
  }

  closeModal(dialogButton: ConfirmationDialogButton = ConfirmationDialogButton.Auto) {
    if (dialogButton == ConfirmationDialogButton.Ok) {
      this.errorComponent.save();
      this.settingsOverrideComponent.save();
      window.location.reload();
    } else {
      if (this.hasChanges) {
        if (confirm('Are you sure you want to close without commiting unsaved changes?')) {
          super.closeModal(dialogButton);
        }
      } else {
        super.closeModal(dialogButton);
      }
    }
  }

  reset() {
    if (confirm('Are you sure you want to reset this tab to defaults?')) {
      if (this.currentTab == Tabs.Errors) {
        this.errorComponent.reset();
      } else {
        this.settingsOverrideComponent.reset();
      }
    }
  }
}
