import { Injectable } from '@angular/core';
import { AuthorizationParams, IdToken } from '@auth0/auth0-spa-js';
import { RmsDataAccessObject } from '@safarilaw-webapp/feature/rms/data-access';
import { AuthClaim, AuthService } from '@safarilaw-webapp/shared/auth';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { APP_USER_KEY } from '@safarilaw-webapp/shared/logging';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RmsAuthService extends AuthService {
  private RmsObject: RmsDataAccessObject;
  private _useCredentials = false;

  constructor() {
    super();
    this.RmsObject = this.inject(RmsDataAccessObject);
  }

  customLogin(redirectTargetRoute?: string, prefilledEmail?: string, connection?: string, startTimer = false, useCredentials = false): BehaviorSubject<any> {
    this._useCredentials = useCredentials;
    return this.login(redirectTargetRoute, prefilledEmail, connection, startTimer);
  }

  override getAuthorizationParamsOverrides(isSilentRenewal: boolean = false): Partial<AuthorizationParams> {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- AUTH parameter
      safari_serveport_login: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- AUTH parameter
      ...(this._useCredentials ? {} : { safari_passwordless: true }),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- AUTH parameter
      ...(this.customThemeId ? { safari_custom_theme: this.customThemeId } : {})
    };
  }

  override handleTokenAndClaims(token, claims) {
    if (token && claims) {
      /* Set these values right away. Calling .login will direct outside the application, but it won't stop AuthGuard from firing first. */
      this._userIdClaimSubject$.next(this.getIdClaim(AuthClaim.UserId));
      this._userEmailClaimSubject$.next(this.getIdClaim(AuthClaim.Email));

      this._store.dispatch(
        this._authRO.default.actions.refreshTokenSuccess({
          payload: {
            claims: claims as IdToken,
            token: token as string
          }
        })
      );
      // At some point (#8428) we'll have to fix up AppCOntext service
      // but this is good enough to get this working
      localStorage.setItem(
        APP_USER_KEY,
        JSON.stringify({
          authUserId: this._userIdClaim
        })
      );

      this.applicationInsights.setAuthenticatedUser(this._userIdClaim, this.currentCompanyId);

      this._store.dispatch(this.RmsObject.Profile.default.actions.loadObject({ payload: { id: SafariObject.NOID } }));
    }
  }

  get customThemeId(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Window global for now
    return (window['Safari']?.customThemeId as string) || null;
  }
}
