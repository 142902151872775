import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AuthService, LogoutReason, STORAGE_AUTH_LOGOUT_REASON } from '@safarilaw-webapp/shared/auth';

@Component({
  imports: [RouterModule],
  selector: 'sl-root-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  public loggedOut = false;
  isAutomaticLogout = false;

  constructor(
    protected _authService: AuthService,
    protected route: ActivatedRoute
  ) {}

  ngOnInit() {
    const logoutReason = localStorage.getItem(STORAGE_AUTH_LOGOUT_REASON);
    this.isAutomaticLogout = logoutReason == LogoutReason.Inactivity;
    if (this._authService.isAuthenticated()) {
      // If we're currently authenticated call authService.logout. Upon success this will come back to this same page
      // but at that second call isAuthenticated will be false
      this._authService.logout({ reason: logoutReason ?? LogoutReason.Manual });
    } else {
      // If coming in here manually OR if this was a loopback from authService.logout set the var to true so we can see the text
      this.loggedOut = true;
    }
  }

  get logoutMessage() {
    return this.isAutomaticLogout ? 'You have been logged out due to inactivity' : 'You have succesfully logged out of the system';
  }
}
