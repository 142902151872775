import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Router } from '@angular/router';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';

import { SafariAccordionComponent } from '@safarilaw-webapp/shared/ui-kit/accordion';
import { ActionsBarComponent } from '@safarilaw-webapp/shared/ui-kit/actions-bar';
import { SafariSmartComponent } from '@safarilaw-webapp/shared/ui-kit/basepage';
import { cloneDeep } from 'lodash-es';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sl-dev-settings-override',
  templateUrl: './settings-override.component.html',
  styleUrls: ['./settings-override.component.scss'],
  imports: [SafariAccordionComponent, ActionsBarComponent, FormsModule]
})
export class SettingsOverrideComponent extends SafariSmartComponent implements OnInit {
  @Input()
  isHosted = false;

  private _defaultAppConfig = null;
  static _toastrConfig: Partial<IndividualConfig<any>> = {
    positionClass: 'toast-top-center',
    easeTime: 0,
    disableTimeOut: false,
    tapToDismiss: true,
    closeButton: true,
    toastClass: 'toast-primary',
    timeOut: 3000
  };
  uiSettingsOverrides = null;

  static applyOverrides(appConfigurationService: AppConfigurationService, toastrService: ToastrService) {
    const configOverride = localStorage.getItem('Safari_Dev_UISettings_Overrides');
    if (configOverride != null && JSON.parse(configOverride) != null) {
      const overrides = JSON.parse(configOverride);
      const featureFlagOverrides = overrides.allFeatureFlags;
      const listOverrides = overrides.list;
      const fileOverrides = overrides.file;
      if (featureFlagOverrides != null) {
        let flagsOverriden = false;
        for (const ffOverride of featureFlagOverrides) {
          if (appConfigurationService['_injectedAppConfig']['featureFlags'][ffOverride.name] != ffOverride.value) {
            flagsOverriden = true;
          }
          appConfigurationService['_injectedAppConfig']['featureFlags'][ffOverride.name] = ffOverride.value;
        }
        if (toastrService && flagsOverriden) {
          toastrService.show('Applied overrides to feature flags', 'Overrides Found', SettingsOverrideComponent._toastrConfig);
        }
      }
      if (fileOverrides != null) {
        if (toastrService && JSON.stringify(appConfigurationService['_injectedAppConfig']['uiSettings']['file']) != JSON.stringify(fileOverrides)) {
          toastrService.show('Applied overrides to file settings', 'Overrides Found', SettingsOverrideComponent._toastrConfig);
        }
        appConfigurationService['_injectedAppConfig']['uiSettings']['file'] = { ...appConfigurationService['_injectedAppConfig']['uiSettings']['file'], ...fileOverrides };
      }
      if (listOverrides != null) {
        if (toastrService && JSON.stringify(appConfigurationService['_injectedAppConfig']['uiSettings']['list']) != JSON.stringify(listOverrides)) {
          toastrService.show('Applied overrides to list settings', 'Overrides Found', SettingsOverrideComponent._toastrConfig);
        }
        appConfigurationService['_injectedAppConfig']['uiSettings']['list'] = { ...appConfigurationService['_injectedAppConfig']['uiSettings']['list'], ...listOverrides };
      }
    }
  }

  private _resetOverridesObject(appConfig: AppConfigurationService) {
    this.uiSettingsOverrides = {
      list: {
        ...appConfig.uiSettings.list
      },
      file: {
        ...appConfig.uiSettings.file
      },
      allFeatureFlags: []
    };

    for (const prop in appConfig.featureFlags) {
      // Do not allow overriding devtools - otherwise you might lock yourself out
      if (prop == 'devTools') {
        continue;
      }
      this.uiSettingsOverrides.allFeatureFlags.push({ name: prop, value: appConfig.featureFlags[prop] });
    }

    this.uiSettingsOverrides.allFeatureFlags.sort((a, b) => a.name.localeCompare(b.name));
  }
  constructor(private _router: Router) {
    super();

    this._resetOverridesObject(this.appConfiguration);
    this._defaultAppConfig = cloneDeep(this.uiSettingsOverrides);
  }

  ngOnInit() {
    super.ngOnInit();
  }
  save() {
    localStorage.setItem('Safari_Dev_UISettings_Overrides', JSON.stringify(this.uiSettingsOverrides));
    SettingsOverrideComponent.applyOverrides(this.appConfiguration, null);

    if (!this.isHosted) {
      void this._router.navigate(['/']);
    }
  }
  reset() {
    let appConfig = null;
    if (!this.isHosted) {
      localStorage.removeItem('Safari_Dev_UISettings_Overrides');
      this.appConfiguration['injectConfiguration']();
      appConfig = this.appConfiguration;
    } else {
      appConfig = this.appConfiguration['getConfiguration']();
    }

    this._resetOverridesObject(appConfig);
  }
  get hasChanges() {
    const localSettings = JSON.stringify(this.uiSettingsOverrides);

    const storageSettings = localStorage.getItem('Safari_Dev_UISettings_Overrides') || JSON.stringify(this._defaultAppConfig);

    return localSettings != storageSettings;
  }

  setMaxListCount(value) {
    this.uiSettingsOverrides.list.maxCountInMemory = +value;
  }
  setItemsPerPage(value) {
    this.uiSettingsOverrides.list.pageSize = +value;
  }
  setPreviewDialogTimeout(value) {
    this.uiSettingsOverrides.file.previewDialogTimeLimitInSeconds = +value;
  }
}
