import * as API from './_rms-api-models.generated';

export type DropdownType = API.PicklistType;
export const DropdownType = API.PicklistType;

export type Country = API.Country;
export const Country = API.Country;

export type State = API.State;
export const State = API.State;

export type ErrorCode = API.ErrorCode;
export const ErrorCode = API.ErrorCode;
export type MatterType = API.MatterType;
export const MatterType = API.MatterType;
export type MalwareScanStatus = API.MalwareScanStatus;
export const MalwareScanStatus = API.MalwareScanStatus;

export type SubmissionStatus = API.SubmissionStatus;
export const SubmissionStatus = API.SubmissionStatus;

export type OneTimePinCommunicationType = API.OneTimePinCommunicationType;
export const OneTimePinCommunicationType = API.OneTimePinCommunicationType;

export type OperationStatus = API.OperationStatus;
export const OperationStatus = API.OperationStatus;

export type PaymentStatus = API.PaymentStatus;
export const PaymentStatus = API.PaymentStatus;

export type PortalReferenceField = API.PortalReferenceField;
export const PortalReferenceField = API.PortalReferenceField;

export type ServingPartyOrgType = API.ServingPartyOrgType;
export const ServingPartyOrgType = API.ServingPartyOrgType;

export type ServingPartyPhoneType = API.ServingPartyPhoneType;
export const ServingPartyPhoneType = API.ServingPartyPhoneType;

export type MatterChild = API.MatterChild;
export const MatterChild = API.MatterChild;

export type MatterField = API.FormField;
export const MatterField = API.FormField;

export type PhoneType = API.PhoneType;
export const PhoneType = API.PhoneType;

export type IntakeFormSection = API.IntakeFormSection;
export const IntakeFormSection = API.IntakeFormSection;

export type IntakeFormLayoutWidgetType = API.IntakeFormLayoutWidgetType;
export const IntakeFormLayoutWidgetType = API.IntakeFormLayoutWidgetType;

export const IntakeFormLayoutWidgetRowType = API.IntakeFormLayoutWidgetRowType;
export type IntakeFormLayoutWidgetRowType = API.IntakeFormLayoutWidgetRowType;

export const SubmissionBatchStatus = API.SubmissionBatchStatus;
export type SubmissionBatchStatus = API.SubmissionBatchStatus;

export const FieldType = API.FieldType;
export type FieldType = API.FieldType;

export enum CaseType {
  Civil = 1,
  Criminal = 2,
  Regulatory = 3,
  Other = 4
}

/* Client Themes */
export enum CustomThemeIds {
  agency = 'agencyTheme',
  walmart = 'clientTheme1'
}
