import { v4 as uuidv4 } from 'uuid';

export abstract class ObjectHelper {
  static isNew(o: any): boolean {
    if (typeof o == 'string') {
      return o == '0';
    }
    // If an object does not have __etag property of if it does but it's blank/null/undefined, then it is new.
    return !Object.prototype.hasOwnProperty.call(o, '__etag') || !o.__etag;
  }
  static getNewId(): string {
    return uuidv4();
  }
}
