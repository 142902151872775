import { Component, Inject, InjectionToken, Injector, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { ErrorHandlerService } from '@safarilaw-webapp/shared/error-handling';
import { ErrorObject, ErrorType } from '@safarilaw-webapp/shared/error-handling-message-parser';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { purgeAllObjectErrors, ReduxWrapperService } from '@safarilaw-webapp/shared/redux';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { Subscription } from 'rxjs';

export const SUPPORT_EMAIL = new InjectionToken<string>('SUPPORT_EMAIL');

@Component({
  imports: [CollapseModule],
  selector: 'sl-root-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss']
})
export class PageErrorComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  currentDateTime: Date = null;
  errorObject: ErrorObject = null;
  url: string = null;
  private _reduxWrapper: ReduxWrapperService;
  private _routeSubscription: Subscription = null;
  isChunkError = false;
  switchToProd = false;
  constructor(
    @Inject(SUPPORT_EMAIL) public supportEmail,
    private _router: Router,
    private _route: ActivatedRoute,
    private _featureFlags: FeatureFlagsService,
    private _loggerService: LoggerService,
    injector: Injector,
    @Optional() private _swUpdate: SwUpdate
  ) {
    this._reduxWrapper = injector.get(ReduxWrapperService);
  }
  ngOnDestroy(): void {
    if (this._routeSubscription != null) {
      this._routeSubscription.unsubscribe();
      this._routeSubscription = null;
    }
  }
  private _parseWindowState() {
    if (window['safari-error-state'] != null && window['safari-error-state'].errorObject != null) {
      this.errorObject = window['safari-error-state'].errorObject;
      this.currentDateTime = new Date();

      // CHUNKERROR COMMENTS
      // If the error contains words "Loading chunk xyz failed" then it means that it came from background chunk failure
      // Remember - main chunk failure will be handled by app.component.ts
      // Anyway - in this case we do something very similar to what appcomponent does. The only difference is that we don't want
      // retryChunkError to throw final error because we are already on the error page anyway - it wouldn't hurt but no need.
      // Also note "isChunkError" parameter. That's used in HTML part to not show anything until retries are exhausted
      // For better understanding of all the pieces just search for "CHUNKERROR COMMENTS" throughout the code.
      if (this.errorObject.message && this.errorObject.message.includes('Loading chunk') && this.errorObject.message.includes('failed')) {
        const urlWithOrigin = window.location.href;
        const chunkCount = ErrorHandlerService.retryChunkError(urlWithOrigin, false);
        if (chunkCount <= 3) {
          this.isChunkError = true;
        }
      }
      this.url = window['safari-error-state'].url;
      const errorUUID: string = this.errorObject && this.errorObject.uuid != null ? this.errorObject.uuid : '--';
      let stringifiedErrorObject = '';
      try {
        stringifiedErrorObject = JSON.stringify(this.errorObject);
      } catch {
        // Just don't blow up if for whatever reason it couldn't stringify it
      }
      // I don't know why we wouldn't be able to stringify the error object, but in case we couldn't we'll just show the old message.

      const errorPageViewMessage = stringifiedErrorObject ?? 'SHOWING PAGE FOR ERROR UUID: ' + errorUUID + ' (ERROR OBJECT COULD NOT BE STRINGIFIED) ';
      if (!this.isChunkError) {
        // We have seen some cases where the error page shows up but the actual error that happened
        // before is not logged. The current theory is that this might be happening because the messages
        // are too close and maybe elmah gives them the same ID or something and just drops one. So
        // we are going to do a brief delay here
        setTimeout(() => {
          this._loggerService.LogErrorPageViewToElmah(errorPageViewMessage, errorUUID);

          if (this._swUpdate?.isEnabled) {
            // It's possible that a user got this error page because they opened the app before some API update,
            // and the update introduced some breaking changes. If the user entered that codepath before the next
            // SW poll time they could easily get the error. So let's poll right there and then
            this._swUpdate.checkForUpdate().catch(err => {
              this._loggerService.LogError(err, window.location.href);
            });
          }
        }, 1000);
      }
    }
  }
  ngOnInit() {
    this._reduxWrapper.dispatchGenericAction(purgeAllObjectErrors());

    this._routeSubscription = this._route.paramMap.subscribe(() => {
      this._parseWindowState();
    });
  }
  forceProdMode() {
    this.switchToProd = true;
  }
  getMessageHeaderFromErrorObject(): string {
    let message = '';
    if (this.errorObject.errorType == ErrorType.Navigation) {
      message += 'Unable to navigate to ' + this.url;
    } else if (this.errorObject.errorType == ErrorType.Validation && this.errorObject.safariApiError) {
      message += 'Please correct the errors below and retry.';
    }
    return message;
  }

  getErrorTitle() {
    if (this.isSafariValidationError()) {
      return 'Validation Error';
    } else if (this.isNavigationError()) {
      return 'Navigation Error';
    } else if (this.isConflictError()) {
      return 'Conflict';
    }
    return 'Error';
  }
  isKnownError() {
    return this.isSafariValidationError() || this.isConflictError() || this.isNavigationError();
  }
  isSafariValidationError() {
    return this.errorObject.errorType == ErrorType.Validation && this.errorObject.safariApiError;
  }
  isNavigationError() {
    return this.errorObject.errorType == ErrorType.Navigation;
  }
  isConflictError() {
    return this.errorObject.errorType == ErrorType.Conflict;
  }

  showDeveloperExceptions() {
    return !this.switchToProd && this._featureFlags.devExceptions;
  }
  async refresh() {
    // There's some chatter out there that window.location.reload will not clear cache in some browsers
    // The solution provided is the one below
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map(name => caches.delete(name)));
    }
    // Now clear local storage
    if (localStorage) {
      localStorage.clear();
    }

    window.location.reload();
  }
}
