import { Injectable } from '@angular/core';
import { ApiCallContext, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { SafariRequired } from '@safarilaw-webapp/shared/crud';
import { DeliveryPortalDataModel } from '../../_rms-api-models.generated';
import { RmsDataAdapter } from '../../shared/rms-base-adapter';
import { DeliveryPortal } from '../models/app/delivery-portal';
import { DeliveryPortalAttachmentAdapter } from './delivery-portal-attachment-adapter';

@Injectable({
  providedIn: 'root'
})
export class DeliveryPortalAdapter extends RmsDataAdapter<DeliveryPortal> {
  constructor(private _deliveryPortalAttachmentAdapter: DeliveryPortalAttachmentAdapter) {
    super();
  }
  fromGetModel(model: DeliveryPortalDataModel, context?: ApiCallContext<any>): SafariRequired<DeliveryPortal> {
    // By passing parentId to child adapters we ensure to get IDs in children that will have this parent's ID prepended,
    const parentContext: ApiCallContext<any> = { __parentId: model.responseId };
    return {
      caseNumber: model.caseNumber,
      companyEntityId: this.helpers.fromApiId(model.companyEntityId),
      companyEntityName: model.companyEntityName,
      companyEntityShortName: model.companyEntityShortName,
      companyId: this.helpers.fromApiId(model.companyId),
      dateDue: this.helpers.fromApiDate(model.dateDue),
      dateInstructionsAcknowledged: model.dateInstructionsAcknowledged,
      dateReceived: this.helpers.fromApiDate(model.dateReceived),
      documentTitle: model.documentTitle,
      expirationDate: this.helpers.fromApiDate(model.expirationDate, false),
      financialAttachments: model.financialAttachments.map(o => this._deliveryPortalAttachmentAdapter.fromGetModel(o, parentContext)),
      id: SafariObject.NOID,
      instructionsAcknowledgedByEmail: model.instructionsAcknowledgedByEmail,
      instructionsAcknowledgedByName: model.instructionsAcknowledgedByName,
      internalReferenceId: model.internalReferenceId,
      invoiceAmount: model.invoiceAmount,
      invoiceContactEmail: model.invoiceContactEmail,
      invoiceContactName: model.invoiceContactName,
      isExpired: model.isExpired,
      isOutsideCouncilDelivery: model.isOutsideCouncilDelivery,
      isRollingProduction: model.isRollingProduction,

      matterName: model.matterName,
      netDueAmount: model.netDueAmount,
      oneTimePinExpirationDate: this.helpers.fromApiDate(model.oneTimePinExpirationDate),
      paymentId: model.paymentId,
      paymentStatus: model.paymentStatus,
      pinDeliveryOptions: model.pinDeliveryOptions,
      portalReferenceField: model.portalReferenceField,
      prepaidAmount: model.prepaidAmount,
      receiptUrl: model.receiptUrl,
      requireOneTimePin: model.requireOneTimePin,
      responseAttachments: model.responseAttachments.map(o => this._deliveryPortalAttachmentAdapter.fromGetModel(o, parentContext)),

      responseId: model.responseId,
      safariId: model.safariId,
      servedAttachments: model.servedAttachments.map(o => this._deliveryPortalAttachmentAdapter.fromGetModel(o, parentContext)),

      servingPartyAdditionalEmail: model.servingPartyAdditionalEmail,
      servingPartyContact: model.servingPartyContact,
      servingPartyEmail: model.servingPartyEmail,
      servingPartyInstructions: model.servingPartyInstructions,
      servingPartyOrgAddress: this.helpers.fromApiAddress(model.servingPartyOrgAddress),

      servingPartyOrgName: model.servingPartyOrgName,
      servingPartyOrgType: model.servingPartyOrgType,
      servingPartyPhoneNumbers: model.servingPartyPhoneNumbers,
      servingPartyRequireInstructionAcknowledgment: model.servingPartyRequireInstructionAcknowledgment,
      subjects: model.subjects,
      supportEmail: model.supportEmail,
      supportName: model.supportName,
      token: model.responseId,
      type: model.type
    };
  }
}
