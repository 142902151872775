/* eslint-disable no-restricted-syntax -- framework use */
/**
 * THIS NEEDS TO BE EXPORTED SO THAT THE WEBAPP CAN REGISTER THE OBJECTS IT WANTS TO USE
 * IN ITS BASE PAGE
 */
import { inject, InjectionToken } from '@angular/core';

import { ISafariObjectState, SafariReduxApiObject, SafariReduxDropdownObject } from '@safarilaw-webapp/shared/redux';

import { createSelector } from '@ngrx/store';
import { AttachmentLink, FileObject, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';

import { Acknowledgment } from '../acknowledgment/models/app/acknowledgment';
import { MyAuth } from '../auth/models/my-auth';
import { DeliveryPortal } from '../delivery-portal/models/app/delivery-portal';
import { DropdownType } from '../enums';
import { IntakePortalForm } from '../intake-portal-form/models/app/intake-portal-form';
import { IntakePortal } from '../intake-portal/models/app/intake-portal';
import { MyIntakePortal } from '../my-intake-portal/models/app/my-intake-portal';
import { OrganizationSearch } from '../ogranization-search/models/app/organization-search';
import { Profile } from '../profile/models/app/profile';

import { Submission } from '../submission/models/app/submission';
import {
  ACKNOWLEDGE_INSTRUCTIONS_SERVICE_TOKEN,
  CONFIRM_RECEIPT_SERVICE_TOKEN,
  DELIVERY_PORTAL_SERVICE_TOKEN,
  DROPDOWN_SERVICE_TOKEN,
  GENERATE_SUBMISSION_TEMPLINK_SERVICE_TOKEN,
  GENERATE_TEMPLINK_SERVICE_TOKEN,
  GENERATE_TEMPZIP_SERVICE_TOKEN,
  INTAKE_PORTAL_FORM_SERVICE_TOKEN,
  INTAKE_PORTAL_SERVICE_TOKEN,
  MY_AUTH_SERVICE_TOKEN,
  MY_INTAKE_PORTAL_SERVICE_TOKEN,
  MY_PROFILE_SERVICE_TOKEN,
  ORGANIZATION_SEARCH_SERVICE_TOKEN,
  SUBMISSION_BATCH_ATTACHMENT_LINK_ATTACHMENT_LINK_SERVICE_TOKEN,
  SUBMISSION_BATCH_SERVICE_TOKEN,
  SUBMISSION_FILE_FILE_OBJECT_SERVICE_TOKEN,
  SUBMISSION_SERVICE_TOKEN
} from './injectable-service-tokens';
import { getSharedFeatureState, IRmsStateDropdown, PROJECT_NAME } from './state.interface';
import { SubmissionBatch } from '../submission-batch/models/app/submission-batch';

export class AttachmentLinkName {
  private static _serveportFileAttachment: string;
  public static get ServeportFileAttachment(): string {
    return AttachmentLinkName._serveportFileAttachment;
  }

  private static _portalZipAttachment: string;
  public static get PortalZipAttachment(): string {
    return AttachmentLinkName._portalZipAttachment;
  }
  private static _submissionAcknowledgmentAttachment: string;
  public static get SubmissionAcknowledgmentAttachment(): string {
    return AttachmentLinkName._submissionAcknowledgmentAttachment;
  }
}

export const DELIVERY_PORTAL_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<DeliveryPortal>, DeliveryPortal>>('DELIVERY_PORTAL_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<DeliveryPortal>, DeliveryPortal>(
      PROJECT_NAME,
      'DeliveryPortal', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(DELIVERY_PORTAL_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.deliveryPortalCombinedState.deliveryPortalState)
    )
});
export const CONFIRM_RECEIPT_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>>('CONFIRM_RECEIPT_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>(
      PROJECT_NAME,
      'ConfirmReceipt', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(CONFIRM_RECEIPT_SERVICE_TOKEN),
      SafariObject.EMPTY,
      null
    )
});
export const ACKNOWLEDGE_INSTRUCTIONS_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Acknowledgment>, Acknowledgment>>('ACKNOWLEDGE_INSTRUCTIONS_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Acknowledgment>, Acknowledgment>(
      PROJECT_NAME,
      'AcknowledgeInstructions', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(ACKNOWLEDGE_INSTRUCTIONS_SERVICE_TOKEN),
      SafariObject.EMPTY,
      null
    )
});
export const MY_AUTH_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<MyAuth>, MyAuth>>('DELIVERY_PORTAL_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<MyAuth>, MyAuth>(
      PROJECT_NAME,
      'MyAuth', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MY_AUTH_SERVICE_TOKEN),
      SafariObject.EMPTY
    )
});

export const DROPDOWN_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxDropdownObject<IRmsStateDropdown>>('DROPDOWN_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxDropdownObject<IRmsStateDropdown>(
      PROJECT_NAME,
      inject(DROPDOWN_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.dropdownCombinedState.dropdownState),
      DropdownType,
      {}
    )
});
export const GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>('GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
      PROJECT_NAME,
      'AttachmentLink', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(GENERATE_TEMPLINK_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.attachmentLinkCombinedState.attachmentLinkState)
    )
});
export const GENERATE_SUBMISSION_TEMPLINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>(
  'GENERATE_SUBMISSION_TEMPLINK_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
        PROJECT_NAME,
        'Submission AttachmentLink', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(GENERATE_SUBMISSION_TEMPLINK_SERVICE_TOKEN),
        SafariObject.EMPTY
      )
  }
);

export const GENERATE_TEMPZIP_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>('GENERATE_TEMPLINK_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
      PROJECT_NAME,
      'AttachmentZip', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(GENERATE_TEMPZIP_SERVICE_TOKEN),
      SafariObject.EMPTY
    )
});
export const INTAKE_PORTAL_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<IntakePortal>, IntakePortal>>('INTAKE_PORTAL_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<IntakePortal>, IntakePortal>(
      PROJECT_NAME,
      'IntakePortal', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(INTAKE_PORTAL_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.intakePortalCombinedState.intakePortalState)
    )
});

export const INTAKE_PORTAL_FORM_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<IntakePortalForm>, IntakePortalForm>>('INTAKE_PORTAL_FORM_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<IntakePortalForm>, IntakePortalForm>(
      PROJECT_NAME,
      'IntakePortalForm', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(INTAKE_PORTAL_FORM_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.intakeFormCombinedState.intakeFormState)
    )
});
export const SUBMISSION_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Submission>, Submission>>('SUBMISSION_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Submission>, Submission>(
      PROJECT_NAME,
      'Submission', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(SUBMISSION_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.submissionCombinedState.submissionState)
    )
});
export const ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<OrganizationSearch>, OrganizationSearch>>('ORGANIZATION_SEARCH_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<OrganizationSearch>, OrganizationSearch>(
      PROJECT_NAME,
      'OrganizationSearch', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(ORGANIZATION_SEARCH_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.organizationCombinedState.searchState),
      null
    )
});
export const MY_PROFILE_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Profile>, Profile>>('MY_PROFILE_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Profile>, Profile>(
      PROJECT_NAME,
      'MyProfile', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MY_PROFILE_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.myCombinedState.profileState)
    )
});
export const MY_INTAKE_PORTAL_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<MyIntakePortal>, MyIntakePortal>>('MY_INTAKE_PORTAL_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<MyIntakePortal>, MyIntakePortal>(
      PROJECT_NAME,
      'MyIntakePortal', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MY_INTAKE_PORTAL_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.myCombinedState.intakePortalState)
    )
});
export const SUBMISSION_BATCH_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<SubmissionBatch>, SubmissionBatch>>('SUBMISSION_BATCH_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<SubmissionBatch>, SubmissionBatch>(
      PROJECT_NAME,
      'SubmissionBatch', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(SUBMISSION_BATCH_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.submissionBatchCombinedState.submissionBatchState)
    )
});
export const SUBMISSION_BATCH_ATTACHMENT_LINK_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>(
  'SUBMISSION_BATCH_ATTACHMENT_LINK_ATTACHMENT_LINK_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
        PROJECT_NAME,
        'SubmissionBatchAttachmentLinkAttachmentLink', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(SUBMISSION_BATCH_ATTACHMENT_LINK_ATTACHMENT_LINK_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => SafariObject.EMPTY)
      )
  }
);
export const SUBMISSION_FILE_FILE_OBJECT_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>>('SUBMISSION_FILE_FILE_OBJECT_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>(
      PROJECT_NAME,
      'SubmissionFileFileObject', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(SUBMISSION_FILE_FILE_OBJECT_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => SafariObject.EMPTY)
    )
});
/*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS ***/
