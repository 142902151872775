import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// The difference between using this and Location.back() is that this will
// have NULL in previousURL if queried on the first entry to the app.
// This can be helpful for something like cancel button that can then check for previous
// URL and if null just redirect to the root of our app. On the other hand if we used Location.back()
// in this case it would send the browser back outside of our app if you clicked Cancel on a deep link
// (which would look weird)
@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {
  private _previousUrl: string;
  private _currentUrl: string;

  constructor(router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._previousUrl = this._currentUrl;
        this._currentUrl = event.url;
      }
    });
  }

  public get previousUrl() {
    return this._previousUrl;
  }
}
