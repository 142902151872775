import { Component } from '@angular/core';

@Component({
  imports: [],
  selector: 'sl-ui-kit-actions-bar',
  templateUrl: './actions-bar.component.html',
  styleUrls: ['./actions-bar.component.scss']
})
export class ActionsBarComponent {
  constructor() {}
}
