import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigurationService } from '@safarilaw-webapp/shared/environment';
import * as appconfigurationCi from '../../appconfiguration/appconfiguration.ci.json';
import * as appconfigurationDemo from '../../appconfiguration/appconfiguration.demo.json';
import * as appconfigurationDev from '../../appconfiguration/appconfiguration.json';
import * as appconfigurationPrd from '../../appconfiguration/appconfiguration.prd.json';
import * as appconfigurationQa from '../../appconfiguration/appconfiguration.qa.json';

@Injectable()
export class RmsConfigurationService extends AppConfigurationService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  injectConfiguration() {
    this._injectedAppConfig = super.getCorrectEnvironment([
      (appconfigurationDev as any).default as AppConfigurationService,
      (appconfigurationCi as any).default as AppConfigurationService,
      (appconfigurationQa as any).default as AppConfigurationService,
      (appconfigurationDemo as any).default as AppConfigurationService,
      (appconfigurationPrd as any).default as AppConfigurationService
    ]);
  }
}
