import { Inject, Injectable } from '@angular/core';
import { ActionReducerMap, combineReducers } from '@ngrx/store';
import { LoggerService } from '@safarilaw-webapp/shared/logging';
import { User } from '../co-user/models/app/user';
import { DROPDOWN_REDUCER, ISafariObjectState, REDUCER, SafariReduxApiObject, SafariReduxDropdownObject } from '@safarilaw-webapp/shared/redux';
import { InboundEmailSetting } from '../inbound-email-setting/models/app/inbound-email-setting';
import { NotificationPreference as NotificationPreferenceModel } from '../notification-preferences/models/app/notification-preference';
import { RegisteredAgentSettings } from '../registered-agent-settings/models/app/registered-agent-settings';

import { AttachmentLink, FileObject, SafariObject, SafariReduxFileTransferObjectDefinition } from '@safarilaw-webapp/shared/common-objects-models';
import { CompanySettings } from '../company-settings/models/app/company-settings';
import { Company } from '../company/models/app/company';
import { COMANAGE_FILE_TRANSFER_OBJECT } from '../feature-co-manage-data-access.module';
import { MyCompanies } from '../my-companies/models/app/my-companies';
import { Team } from '../team/models/app/team';
import * as fileUpload from './file-transfer';
import {
  COMPANY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN,
  COMPANY_SETTINGS_REDUX_OBJECT_TOKEN,
  DROPDOWN_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_ACCOUNTS_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_LOGINS_TEMPLINK_REDUX_OBJECT_TOKEN,
  MY_COMPANIES_REDUX_OBJECT_TOKEN,
  MY_COMPANY_REDUX_OBJECT_TOKEN,
  MY_INBOUND_EMAIL_SETTINGS_REDUX_OBJECT_TOKEN,
  MY_SIGNATURE_FILE_OBJECT_REDUX_OBJECT_TOKEN,
  MY_USER_REDUX_OBJECT_TOKEN,
  NOTIFICATION_PREFERENCES_REDUX_OBJECT_TOKEN,
  REGISTERED_AGENT_SETTINGS_REDUX_OBJECT_TOKEN,
  TEAM_REDUX_OBJECT_TOKEN,
  USER_REDUX_OBJECT_TOKEN,
  USER_SIGNATURE_REDUX_OBJECT_TOKEN,
  USER_WELCOME_EMAIL_REDUX_OBJECT_TOKEN
} from './injectable-object-tokens';
import { ICoManageState, ICoManageStateDropdown } from './state.interface';

@Injectable({
  providedIn: 'root'
})
export class CoManageDataAccessObject {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-shadow -- typescript confused, ignore
    @Inject(MY_USER_REDUX_OBJECT_TOKEN) public MyUser: SafariReduxApiObject<ISafariObjectState<User>, User>,
    // eslint-disable-next-line @typescript-eslint/no-shadow -- typescript confused, ignore
    @Inject(MY_COMPANIES_REDUX_OBJECT_TOKEN) public MyCompanies: SafariReduxApiObject<ISafariObjectState<MyCompanies>, MyCompanies>,
    // eslint-disable-next-line @typescript-eslint/no-shadow -- typescript confused, ignore
    @Inject(USER_REDUX_OBJECT_TOKEN) public User: SafariReduxApiObject<ISafariObjectState<User>, User>,
    // eslint-disable-next-line @typescript-eslint/no-shadow -- typescript confused, ignore
    @Inject(TEAM_REDUX_OBJECT_TOKEN) public Team: SafariReduxApiObject<ISafariObjectState<Team>, Team>,
    // eslint-disable-next-line @typescript-eslint/no-shadow -- typescript confused, ignore
    @Inject(MY_COMPANY_REDUX_OBJECT_TOKEN) public MyCompany: SafariReduxApiObject<ISafariObjectState<Company>, Company>,
    @Inject(COMPANY_SETTINGS_REDUX_OBJECT_TOKEN) public Company_Settings: SafariReduxApiObject<ISafariObjectState<CompanySettings>, CompanySettings>,
    @Inject(MY_INBOUND_EMAIL_SETTINGS_REDUX_OBJECT_TOKEN) public MyCompany_InboundSettings: SafariReduxApiObject<ISafariObjectState<InboundEmailSetting>, InboundEmailSetting>,
    @Inject(NOTIFICATION_PREFERENCES_REDUX_OBJECT_TOKEN) public NotificationPreference: SafariReduxApiObject<ISafariObjectState<NotificationPreferenceModel>, NotificationPreferenceModel>,
    @Inject(USER_SIGNATURE_REDUX_OBJECT_TOKEN) public UserSignatureFileObject: SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>,
    @Inject(USER_WELCOME_EMAIL_REDUX_OBJECT_TOKEN) public NewUserEmail: SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>,

    @Inject(REGISTERED_AGENT_SETTINGS_REDUX_OBJECT_TOKEN) public Company_RegisteredAgentSettings: SafariReduxApiObject<ISafariObjectState<RegisteredAgentSettings>, RegisteredAgentSettings>,
    @Inject(GENERATE_REPORTS_ACCOUNTS_TEMPLINK_REDUX_OBJECT_TOKEN) public ReportAccountsLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    @Inject(GENERATE_REPORTS_LOGINS_TEMPLINK_REDUX_OBJECT_TOKEN) public ReportLoginsLink: SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>,
    @Inject(MY_SIGNATURE_FILE_OBJECT_REDUX_OBJECT_TOKEN) public MySignatureFileObject: SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>,
    // "GLOBAL" OBJECTS
    @Inject(DROPDOWN_REDUX_OBJECT_TOKEN) public Dropdown: SafariReduxDropdownObject<ICoManageStateDropdown>,
    @Inject(COMANAGE_FILE_TRANSFER_OBJECT) public FileTransfer: SafariReduxFileTransferObjectDefinition,
    @Inject(COMPANY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN) public CompanyLogoFileObject: SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>

    /*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS (1) ***/
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class ReducerService {
  constructor(
    public CoManageObject: CoManageDataAccessObject,
    private _logger: LoggerService
  ) {}
  get reducers(): ActionReducerMap<ICoManageState> {
    return {
      teamCombinedState: combineReducers({
        teamState: REDUCER(this.CoManageObject.Team, this._logger)
      }),
      companyCombinedState: combineReducers({
        companySettingsState: REDUCER(this.CoManageObject.Company_Settings, this._logger),
        companyState: REDUCER(this.CoManageObject.MyCompany, this._logger),
        inboundEmailSettingState: REDUCER(this.CoManageObject.MyCompany_InboundSettings, this._logger),
        registeredAgentSettingsState: REDUCER(this.CoManageObject.Company_RegisteredAgentSettings, this._logger)
      }) as any,
      dropdownCombinedState: combineReducers({
        dropdownState: DROPDOWN_REDUCER(this.CoManageObject.Dropdown)
      }),
      fileCombinedState: fileUpload.combinedReducers,
      notificationPreferenceCombinedState: combineReducers({
        notificationPreferenceState: REDUCER(this.CoManageObject.NotificationPreference, this._logger)
      }),
      myCombinedState: combineReducers({
        companiesState: REDUCER(this.CoManageObject.MyCompanies, this._logger),
        userState: REDUCER(this.CoManageObject.MyUser, this._logger)
      }),
      userCombinedState: combineReducers({
        userState: REDUCER(this.CoManageObject.User, this._logger)
      })
      /*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS (2) ***/
    };
  }
}
