/* eslint-disable @typescript-eslint/no-unsafe-assignment -- some params, like context for example, are any */

// TODO: I think we should add a hidden "state" property to each one of the objects as it moves to a different
// state. We currently don't make that distinction anywhere but maybe in the future we'd want to know
// which actual state triggered the selector when we observe on one of many states.
// (currently we observe usually only on combo of failed/current and since failed object always has errors property
// we use that. But if we wanted to observe on saving/saved/current and wanted to know exactly which one of the three
// is emitting we wouldn't know at this time)
import { createReducer, on } from '@ngrx/store';
import {
  cancelAllFiles,
  clearAllFileStores,
  CrudServiceParams,
  DefaultFileActionConverter,
  FileOperationInfo,
  FileOperationType,
  FilePreviewRequest,
  FilePreviewResponse,
  FilePreviewResponseType,
  IReducerErrorLogger,
  SafariObject,
  SafariObjectId
} from '@safarilaw-webapp/shared/common-objects-models';

import {
  ActionErrorBase,
  ClearObjectErrorActionInfo,
  DeleteObjectActionFailInfo,
  DeleteObjectActionInfo,
  DeleteObjectActionSuccessInfo,
  LoadObjectActionFailInfo,
  LoadObjectActionInfo,
  LoadObjectActionSuccessInfo,
  LoadObjectHistoryActionInfo,
  LoadObjectHistoryActionSuccessInfo,
  LoadObjectListActionFailInfo,
  LoadObjectListActionInfo,
  LoadObjectListActionSuccessInfo,
  MoveLoadedListToCurrentActionInfo,
  MoveLoadedToCurrentActionInfo,
  UpdateObjectListActionFailInfo,
  UpdateObjectListActionInfo,
  UpdateObjectListActionSuccessInfo,
  UpdateOrCreateActionFailInfo,
  UpdateOrCreateActionInfo,
  UpdateOrCreateActionSuccessInfo
} from '../models/object';
import { LoadSearchActionSuccessInfo } from '../models/search';
import { clearAllObjectStates, purgeAllObjectErrors } from './actions/actions';
import { DefaultDropdownActionConverter, DropdownIdActionInfo, DropdownLoadSuccessActionInfo } from './actions/dropdown';
import { DefaultActionConverter } from './actions/object';
import { DefaultSearchActionConverter } from './actions/search';
import { reduxObjectInitState } from './initial';
import { IDropdownState, IFileUploadState, ISafariObjectState, ISearchState } from './interface';

export type ActionExtensions = {
  correlationId?: any;
  context?: any;
  actionId?: string;
  error?: any;
  filter?: CrudServiceParams;
  countOnly?: boolean;

  __priorId?: SafariObjectId;
};
/**
 * This is the object that ends up in the state. It's a combination of the object that was returned from the API
 * AND some additional action-specific properties that the observer can query
 */
export type ObjectWithActionInfo = ActionExtensions & Partial<SafariObject>;

export const findIndexInArray = <T extends ObjectWithActionInfo>(arr: Array<T>, payload: ObjectWithActionInfo, originalPayload: ObjectWithActionInfo, isList = false) => {
  // This function "normalizes" the ID to a comma separated string
  const idToCommaString = (object: ObjectWithActionInfo): SafariObjectId => {
    if (object == null) {
      return null;
    }
    return SafariObject.id(object.id);
  };
  // We will be checking object against the main payload id and original Payload's ID
  // Not 100% sure that we even need to check originalPayload since what's in the array
  // will have __priorId (that should be the same as what originalPayload.id will have)
  // but leaving this for now
  const allIdsToCheck = [idToCommaString(payload), idToCommaString(originalPayload)];

  // TODO: We need to replace some of these with SafariObject.idEqual instead of doing all that stringifying
  return arr.findIndex(o => {
    // ID null checks should be OK only if actionId from the payload is also NULL
    // (I m not sure when this is ever happening but it was in the old reducer so leaving it here)
    if (o.id == null && payload.id == null && o.actionId == null && payload.actionId == null) {
      return true;
    }
    // Check for actionIds (actionIds basically) - if they match it's a match, no other checks needed
    // We rarely look for the exact actionId match though, but it's there
    if (payload.actionId && o.actionId == payload.actionId) {
      return true;
    }
    // I m not sure why ID would ever be null in the state array but the original code had that check so it's
    // here too
    if (o.id != null) {
      const arrayId = idToCommaString(o as ObjectWithActionInfo);

      for (const idToCheck of allIdsToCheck) {
        if (SafariObject.idEqual(idToCheck, arrayId)) {
          return true;
        }
      }
    }
    if (o.__priorId != null) {
      const arrayId = SafariObject.id(o.__priorId);
      for (const idToCheck of allIdsToCheck) {
        if (SafariObject.idEqual(idToCheck, arrayId)) {
          return true;
        }
      }
    }
    return false;
  });
};

/**
 * Use this helper function when writing custom reducers. It will find an object in the state array based on either id or actionId
 * and will return it to the caller
 * @param array - Array of objects
 * @param payload  - Payload of the object to be modified. Payload must include ID property in order to find the object
 * @returns Object from the array or null
 */
export const getObjectInArray = <T extends ObjectWithActionInfo>(array: Array<T>, payload: ObjectWithActionInfo, originalPayload: ObjectWithActionInfo, isList = false) => {
  // Don't allow null arrays in the state.
  let arr: T[] = [];
  if (array) {
    arr = array;
  }
  const index = findIndexInArray(arr, payload, originalPayload, isList);
  if (index >= 0) {
    return arr[index];
  }
  return undefined;
};
/**
 * Use this helper function when writing custom reducers. It will find an object in the state array based on either id or actionId
 * and will overwrite it with the payload that is passed in
 * @param array - Array of objects
 * @param payload  - Payload of the object to be modified. Payload must include ID property in order to find the object
 * @param insertIfNotFound - Boolean flag specifying whether the object will be inserted into the state array if it wasn't found. Default - true
 * @param merge - Boolean that specifies whether the object will fully replace the existing object (merge false) or just merge with what's in there already (merge true)
 *                Default - true
 * @returns Array of objects that was passed in, now containing desired object modifications
 */
export const modifyObjectInArray = <T extends ObjectWithActionInfo = ObjectWithActionInfo>(
  array: Array<T>,
  payload: ObjectWithActionInfo,
  originalPayload: ObjectWithActionInfo,
  insertIfNotFound = true,
  merge = false,
  isList = false
): Array<T> => {
  // Don't allow null arrays in the state.
  let arr: T[] = [];
  if (array) {
    arr = [...array];
  }
  // Make sure to protect against someone passing null payload here. If null just return the array as is
  if (payload == null) {
    return arr;
  }
  const index = findIndexInArray(arr, payload, originalPayload, isList);
  if (index >= 0) {
    if (!merge) {
      arr[index] = { ...(payload as T) };
    } else {
      const mergedObject = { ...arr[index], ...payload };
      arr[index] = mergedObject;
    }
  } else {
    if (insertIfNotFound && arr.length == 0) {
      arr.push({ ...(payload as T) });
    } else if (insertIfNotFound) {
      arr.push({ ...(payload as T) });
    }
  }
  return arr;
};
/**
 * Use this helper function when writing custom reducers. It will find an object in the state array based on either id or actionId
 * and will remove it from the array
 * @param array - Array of objects
 * @param payload  - Payload of the object to be modified. Payload must include ID property in order to find the object
 * @returns Array of objects that was passed in, with the object removed
 */
export const removeObjectFromArray = <T extends ObjectWithActionInfo = ObjectWithActionInfo>(array: Array<T>, payload: ObjectWithActionInfo, originalPayload: ObjectWithActionInfo, isList = false) => {
  // Don't allow null arrays in the state.
  if (!array) {
    return [];
  }
  const arr: T[] = [...array];

  const index = findIndexInArray(arr, payload, originalPayload, isList);
  if (index >= 0) {
    arr.splice(index, 1);
  }
  return arr;
};
const checkActionPayload = (action: { payload?: any }, logger: IReducerErrorLogger) => {
  if (action.payload == null) {
    logger.LogReducerNullPayload(action);
  }
};
export const createReducerFromFunctionArray = <S extends ISafariObjectState<T>, T extends SafariObject = any>(
  defaultState: S,
  actions: DefaultActionConverter<T>,
  additionalReducers = null,
  logger: IReducerErrorLogger
) => {
  const onArray = [];

  if (actions.loadObjectList) {
    onArray.push(
      on(actions.loadObjectList, (state: ISafariObjectState<T>, action: LoadObjectListActionInfo) => {
        if (action.abort) {
          // When returning state make sure to remove everything from loading, otherwise
          // the state will look incorrect (items aborted will end up getting stuck in "loading" array -
          // may not cause any issues by itself but it's weird)
          return {
            ...state,
            ...{
              list: {
                ...state.list,
                loading: []
              }
            }
          };
        }
        checkActionPayload(action, logger);
        // Add this list to loading state
        const loading = modifyObjectInArray(state.list.loading, action.payload, null, true, false, true);
        // Remove the list from loaded state if it's there
        const loaded = removeObjectFromArray(state.list.loaded, action.payload, null, true);
        // Remove the list from failedToLoad state if it's there
        const failedToLoad = removeObjectFromArray(state.list.failedToLoad, action.payload, null, true);
        return { ...state, list: { ...state.list, ...{ loading, loaded, failedToLoad } } };
      })
    );
  }
  if (actions.loadObjectListSuccess) {
    onArray.push(
      on(actions.loadObjectListSuccess, (state: ISafariObjectState<T>, action: LoadObjectListActionSuccessInfo<T>) => {
        checkActionPayload(action, logger);
        const noAutoSwapFlag = action.originalOptions?.noAutoSwap;

        // Remove this list from loading state
        const loading = removeObjectFromArray(state.list.loading, action.payload, action.originalPayload, true);
        const currentOrLoaded = {
          ...action.payload,
          countOnly: action.originalOptions?.countOnly,
          context: action.context,
          correlationId: action.correlationId,
          actionId: action.actionId
        };
        // Add the list to loaded state
        const loaded = noAutoSwapFlag ? modifyObjectInArray(state.list.loaded, currentOrLoaded, action.originalPayload, true, false, true) : state.list.loaded;

        const current = noAutoSwapFlag ? state.list.current : modifyObjectInArray(state.list.current, currentOrLoaded, action.originalPayload, true, false, true);
        return {
          ...state,
          list: { ...state.list, ...{ loaded, loading, current } }
        };
      })
    );
  }
  if (actions.loadObjectListFail) {
    onArray.push(
      on(actions.loadObjectListFail, (state: ISafariObjectState<T>, action: LoadObjectListActionFailInfo) => {
        checkActionPayload(action, logger);
        // Remove this list from loading state
        const loading = removeObjectFromArray(state.list.loading, action.payload, action.originalPayload, true);
        // Add this list  to failed to load state
        // NOTE: In save methods when there is a failure there is a precise payload (object that the user sent)
        // that we then just append error to. In methods like loadObject and loadObjectList there is no payload
        // per se - it's just either an ID or nothing. However, we do have originalPayload property in load actions
        // that is usually used for 204. If needed this same originalPayload can be passed in to come back in
        // case of failures (it will be merged with the action's payload, which again is usually either ID or nothing)
        // Squirrel uses this to either get defaults if it 404s or the actual value from the DB without really
        // caring where it came from.
        const failed = {
          ...action.payload,
          ...action.originalPayload,
          error: action.error,
          context: action.context,
          correlationId: action.correlationId,
          actionId: action.actionId
        };

        const failedToLoad = modifyObjectInArray(state.list.failedToLoad, failed, action.originalPayload, true, false, true);
        return {
          ...state,
          list: { ...state.list, ...{ failedToLoad, loading } }
        };
      })
    );
  }
  if (actions.clearState) {
    onArray.push(
      on(actions.clearState, (state: ISafariObjectState<T>) => ({
        ...state,
        ...reduxObjectInitState
      }))
    );
  }
  if (actions.loadObject) {
    onArray.push(
      on(actions.loadObject, (state: ISafariObjectState<T>, action: LoadObjectActionInfo<T>) => {
        if (action.abort) {
          // When returning state make sure to remove everything from loading, otherwise
          // the state will look incorrect (items aborted will end up getting stuck in "loading" array -
          // may not cause any issues by itself but it's weird)
          return {
            ...state,
            ...{
              object: {
                ...state.object,
                loading: []
              }
            }
          };
        }

        checkActionPayload(action, logger);
        // Add this object to loading state
        const loading = modifyObjectInArray(state.object.loading, action.payload, null);
        // Remove this object from loaded state if it's there
        const loaded = removeObjectFromArray(state.object.loaded, action.payload, null);
        // Remove this object from failedToLoad state if it's there
        const failedToLoad = removeObjectFromArray(state.object.failedToLoad, action.payload, null);

        return { ...state, object: { ...state.object, loading, loaded, failedToLoad } };
      })
    );
  }
  if (actions.loadObjectSuccess) {
    onArray.push(
      on(actions.loadObjectSuccess, (state: ISafariObjectState<T>, action: LoadObjectActionSuccessInfo<T>) => {
        checkActionPayload(action, logger);

        const noAutoSwapFlag = action.originalOptions?.noAutoSwap;
        const objectWithPriorId: ObjectWithActionInfo = {
          ...action.payload,
          __priorId: action.originalPayload?.id,
          correlationId: action.correlationId,
          context: action.context,
          actionId: action.actionId
        };
        // Add this object to loaded state ONLY if we're chose not to autoswap (default behavior is to autoswap)
        // This can be used for backbuffering, so for example, waiting for object to load, then listening on it
        // and then swapping only if the object is not dirty or something like that
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- context can be anything
        const loaded = noAutoSwapFlag
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- context can be anything
            modifyObjectInArray(state.object.loaded, objectWithPriorId, action.originalPayload)
          : state.object.loaded;
        // The opposite is true for this one. If we chose not to autoswap the current will remain as is and we'll need to invoke
        // the swap action to switch them. Otherwise if we're autoswapping (default) this payload goes straight into the current
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- context can be anything
        const current = noAutoSwapFlag
          ? state.object.current
          : // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- context can be anything
            modifyObjectInArray(state.object.current, objectWithPriorId, action.originalPayload);
        // Remove this object from loading state
        const loading = removeObjectFromArray(state.object.loading, objectWithPriorId, action.originalPayload);

        return { ...state, object: { ...state.object, loading, loaded, current } };
      })
    );
  }
  if (actions.loadObjectFail) {
    onArray.push(
      on(actions.loadObjectFail, (state: ISafariObjectState<T>, action: LoadObjectActionFailInfo) => {
        checkActionPayload(action, logger);

        // Remove this object from loading state
        const loading = removeObjectFromArray(state.object.loading, action.payload, action.originalPayload);
        // Add this object to failedToload state
        // NOTE: In save methods when there is a failure there is a precise payload (object that the user sent)
        // that we then just append error to. In methods like loadObject and loadObjectList there is no payload
        // per se - it's just either an ID or nothing. However, we do have originalPayload property in load actions
        // that is usually used for 204. If needed this same originalPayload can be passed in to come back in
        // case of failures (it will be merged with the action's payload, which again is usually either ID or nothing)
        // Squirrel uses this to either get defaults if it 404s or the actual value from the DB without really
        // caring where it came from.
        const failed = {
          ...action.payload,
          ...action.originalPayload,
          error: action.error,
          context: action.context,
          correlationId: action.correlationId,
          actionId: action.actionId
        };
        const failedToLoad = modifyObjectInArray(state.object.failedToLoad, failed, action.originalPayload);

        return { ...state, object: { ...state.object, loading, failedToLoad } };
      })
    );
  }
  // DELETE
  if (actions.deleteObject) {
    onArray.push(
      on(actions.deleteObject, (state: ISafariObjectState<T>, action: DeleteObjectActionInfo) => {
        if (action.abort) {
          // When returning state make sure to remove everything from loading, otherwise
          // the state will look incorrect (items aborted will end up getting stuck in "loading" array -
          // may not cause any issues by itself but it's weird)
          return {
            ...state,
            ...{
              object: {
                ...state.object,
                deleting: []
              }
            }
          };
        }

        checkActionPayload(action, logger);
        // Add this object to loading state
        const deleting = modifyObjectInArray(state.object.deleting, action.payload, null);
        // Remove this object from loaded state if it's there
        const deleted = removeObjectFromArray(state.object.deleted, action.payload, null);
        // Remove this object from failedToLoad state if it's there
        const failedToDelete = removeObjectFromArray(state.object.failedToDelete, action.payload, null);

        return { ...state, object: { ...state.object, deleting, deleted, failedToDelete } };
      })
    );
  }
  if (actions.deleteObjectSuccess) {
    onArray.push(
      on(actions.deleteObjectSuccess, (state: ISafariObjectState<T>, action: DeleteObjectActionSuccessInfo) => {
        checkActionPayload(action, logger);

        // Add this object to loaded state ONLY if we're chose not to autoswap (default behavior is to autoswap)
        // This can be used for backbuffering, so for example, waiting for object to load, then listening on it
        // and then swapping only if the object is not dirty or something like that
        const deleted = modifyObjectInArray(
          state.object.deleted,
          { ...action.payload, context: action.context, correlationId: action.correlationId, actionId: action.actionId },
          action.originalPayload
        );
        // The opposite is true for this one. If we chose not to autoswap the current will remain as is and we'll need to invoke
        // the swap action to switch them. Otherwise if we're autoswapping (default) this payload goes straight into the current
        // Remove this object from loading state
        const deleting = removeObjectFromArray(state.object.deleting, action.payload, action.originalPayload);

        return { ...state, object: { ...state.object, deleting, deleted } };
      })
    );
  }
  if (actions.deleteObjectFail) {
    onArray.push(
      on(actions.deleteObjectFail, (state: ISafariObjectState<T>, action: DeleteObjectActionFailInfo) => {
        checkActionPayload(action, logger);
        // Remove this object from loading state
        const deleting = removeObjectFromArray(state.object.deleting, action.payload, action.originalPayload);
        // Add this object to failedToload state
        const failedToDelete = modifyObjectInArray(
          state.object.failedToDelete,
          { ...action.payload, context: action.context, correlationId: action.correlationId, actionId: action.actionId },
          action.originalPayload
        );

        return { ...state, object: { ...state.object, deleting, failedToDelete } };
      })
    );
  }
  //
  if (actions.createOrUpdateObject) {
    onArray.push(
      on(actions.createOrUpdateObject, (state: ISafariObjectState<T>, action: UpdateOrCreateActionInfo<T>) => {
        if (action.abort) {
          // When returning state make sure to remove everything from loading, otherwise
          // the state will look incorrect (items aborted will end up getting stuck in "loading" array -
          // may not cause any issues by itself but it's weird)
          return {
            ...state,
            ...{
              object: {
                ...state.object,
                saving: []
              }
            }
          };
        }

        checkActionPayload(action, logger);
        // Add this object to saving state
        const saving = modifyObjectInArray(state.object.saving, action.payload, null);
        // Remove this object from failedToSave state if it's there
        let current = state.object.current;
        if (action.options?.removeCurrent) {
          current = removeObjectFromArray(state.object.current, action.payload, null);
        }
        const failedToSave = removeObjectFromArray(state.object.failedToSave, action.payload, null);
        return { ...state, object: { ...state.object, saving, current, failedToSave } };
      })
    );
  }
  if (actions.updateObjectSuccess) {
    onArray.push(
      on(actions.updateObjectSuccess, (state: ISafariObjectState<T>, action: UpdateOrCreateActionSuccessInfo<T>) => {
        checkActionPayload(action, logger);
        const noAutoSwapFlag = action.originalOptions?.noAutoSwap;
        const objectWithPriorId = {
          ...action.payload,
          __priorId: action.originalPayload?.id,
          context: action.context,
          correlationId: action.correlationId,
          actionId: action.actionId
        };
        // Add this object to saved state ONLY if we're chose not to autoswap (default behavior is to autoswap)
        // This can be used for backbuffering, so for example, waiting for object to load, then listening on it
        // and then swapping only if the object is not dirty or something like that
        const saved = noAutoSwapFlag ? modifyObjectInArray(state.object.saved, objectWithPriorId, action.originalPayload) : state.object.saved;
        // The opposite is true for this one. If we chose not to autoswap the current will remain as is and we'll need to invoke
        // the swap action to switch them. Otherwise if we're autoswapping (default) this payload goes straight into the current
        const current = noAutoSwapFlag ? state.object.current : modifyObjectInArray(state.object.current, objectWithPriorId, action.originalPayload);
        // Remove this object from saving  state

        const saving = removeObjectFromArray(state.object.saving, objectWithPriorId, action.originalPayload);

        return { ...state, object: { ...state.object, saving, current, saved } }; //, forList };
      })
    );
  }
  if (actions.createObjectSuccess) {
    onArray.push(
      on(actions.createObjectSuccess, (state: ISafariObjectState<T>, action: UpdateOrCreateActionSuccessInfo<T>) => {
        checkActionPayload(action, logger);
        const objectWithPriorId = {
          ...action.payload,
          __priorId: action.originalPayload?.id,
          context: action.context,
          correlationId: action.correlationId,
          actionId: action.actionId
        };
        const noAutoSwapFlag = action.originalOptions?.noAutoSwap;
        // Add this object to saved state ONLY if we're chose not to autoswap (default behavior is to autoswap)
        // This can be used for backbuffering, so for example, waiting for object to load, then listening on it
        // and then swapping only if the object is not dirty or something like that
        const saved = noAutoSwapFlag ? modifyObjectInArray(state.object.saved, objectWithPriorId, action.originalPayload) : state.object.saved;
        // The opposite is true for this one. If we chose not to autoswap the current will remain as is and we'll need to invoke
        // the swap action to switch them. Otherwise if we're autoswapping (default) this payload goes straight into the current
        const current = noAutoSwapFlag ? state.object.current : modifyObjectInArray(state.object.current, objectWithPriorId, action.originalPayload);
        // Remove this object from saving  state

        const saving = removeObjectFromArray(state.object.saving, objectWithPriorId, action.originalPayload);
        // If save was succesful and there was a previous failure in the state remove it
        const failedToSave = noAutoSwapFlag ? state.object.failedToSave : removeObjectFromArray(state.object.failedToSave, objectWithPriorId, action.originalPayload);
        return { ...state, object: { ...state.object, saving, failedToSave, current, saved } }; //, forList };
      })
    );
  }
  if (actions.createOrUpdateObjectFail) {
    onArray.push(
      on(actions.createOrUpdateObjectFail, (state: ISafariObjectState<T>, action: UpdateOrCreateActionFailInfo<T>) => {
        checkActionPayload(action, logger);
        //const noAutoSwapFlag = action.noAutoSwap;
        //let current = state.object.current;
        // if (!noAutoSwapFlag) {
        //   // If we meant to autoswap (default) then we'll also revert the object that failed to update
        //   // back to what the user originally sent
        //   current = modifyObjectInArray(state.object.current,getObjectInArray(state.object.saving, action.payload) )
        // }
        // Remove this object from saving  state
        const saving = removeObjectFromArray(state.object.saving, action.payload, action.originalPayload);
        // get mergeOnError object if it was passed. It's usually used in rare circumstances when we want to revert
        // user input after error

        // When a reducer gets CreateOrUpdateObjectFail it will add entry to "failed"
        // array, together with "error" entry that will be pulled up by the form for display
        const failed: ObjectWithActionInfo = {
          ...action.payload,
          error: action.error,
          context: action.context,
          correlationId: action.correlationId,
          actionId: action.actionId
        };
        const failedToSave = modifyObjectInArray(state.object.failedToSave, failed, action.originalPayload);

        return { ...state, object: { ...state.object, saving, failedToSave } };
      })
    );
  }
  //////
  if (actions.updatePartialObject) {
    onArray.push(
      on(actions.updatePartialObject, (state: ISafariObjectState<T>, action: UpdateOrCreateActionInfo<T>) => {
        //  console.log('create update')
        if (action.abort) {
          // When returning state make sure to remove everything from loading, otherwise
          // the state will look incorrect (items aborted will end up getting stuck in "loading" array -
          // may not cause any issues by itself but it's weird)
          return {
            ...state,
            ...{
              object: {
                ...state.object,
                saving: []
              }
            }
          };
        }

        checkActionPayload(action, logger);
        // Add this object to saving state
        const saving = modifyObjectInArray(state.object.saving, action.payload, null);
        // Remove this object from failedToSave state if it's there

        const failedToSave = removeObjectFromArray(state.object.failedToSave, action.payload, null);
        return { ...state, object: { ...state.object, saving, failedToSave } };
      })
    );
  }
  if (actions.updatePartialObjectSuccess) {
    onArray.push(
      on(actions.updatePartialObjectSuccess, (state: ISafariObjectState<T>, action: UpdateOrCreateActionSuccessInfo<T>) => {
        checkActionPayload(action, logger);
        const noAutoSwapFlag = action.originalOptions?.noAutoSwap;
        const savedOrCurrent = { ...action.payload, context: action.context, correlationId: action.correlationId, actionId: action.actionId };
        // Add this object to saved state ONLY if we're chose not to autoswap (default behavior is to autoswap)
        // This can be used for backbuffering, so for example, waiting for object to load, then listening on it
        // and then swapping only if the object is not dirty or something like that
        const saved = noAutoSwapFlag ? modifyObjectInArray(state.object.saved, savedOrCurrent, action.originalPayload) : state.object.saved;
        // The opposite is true for this one. If we chose not to autoswap the current will remain as is and we'll need to invoke
        // the swap action to switch them. Otherwise if we're autoswapping (default) this payload goes straight into the current
        const current = noAutoSwapFlag ? state.object.current : modifyObjectInArray(state.object.current, savedOrCurrent, action.originalPayload);
        // Remove this object from saving  state

        const saving = removeObjectFromArray(state.object.saving, action.payload, action.originalPayload);

        return { ...state, object: { ...state.object, saving, current, saved } }; //, forList };
      })
    );
  }

  if (actions.updatePartialObjectFail) {
    onArray.push(
      on(actions.updatePartialObjectFail, (state: ISafariObjectState<T>, action: UpdateOrCreateActionFailInfo<T>) => {
        checkActionPayload(action, logger);
        //const noAutoSwapFlag = action.noAutoSwap;
        //let current = state.object.current;
        // if (!noAutoSwapFlag) {
        //   // If we meant to autoswap (default) then we'll also revert the object that failed to update
        //   // back to what the user originally sent
        //   current = modifyObjectInArray(state.object.current,getObjectInArray(state.object.saving, action.payload) )
        // }
        // Remove this object from saving  state
        const saving = removeObjectFromArray(state.object.saving, action.payload, action.originalPayload);
        // get mergeOnError object if it was passed. It's usually used in rare circumstances when we want to revert
        // user input after error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- need flexibility here. we don't know what onmergeerror could possibly be

        // When a reducer gets CreateOrUpdateObjectFail it will add entry to "failed"
        // array, together with "error" entry that will be pulled up by the form for display
        const failed: ObjectWithActionInfo = {
          ...action.payload,
          error: action.error,
          context: action.context,
          correlationId: action.correlationId,
          actionId: action.actionId
        };

        const failedToSave = modifyObjectInArray(state.object.failedToSave, failed, action.originalPayload);

        return { ...state, object: { ...state.object, saving, failedToSave } };
      })
    );
  }
  /////
  if (actions.loadObjectHistory) {
    onArray.push(
      on(actions.loadObjectHistory, (state: ISafariObjectState<T>, action: LoadObjectHistoryActionInfo) => {
        if (action.abort) {
          // When returning state make sure to remove everything from loading, otherwise
          // the state will look incorrect (items aborted will end up getting stuck in "loading" array -
          // may not cause any issues by itself but it's weird)
          return {
            ...state,
            ...{
              auditHistory: {
                ...state.auditHistory,
                loading: []
              }
            }
          };
        }
        checkActionPayload(action, logger);
        // Add this object to loading state
        const loading = modifyObjectInArray(state.auditHistory.loading, action.payload, null);
        // Remove this object from loaded state if it's there
        const loaded = removeObjectFromArray(state.auditHistory.loaded, action.payload, null);
        // Remove this object from failedToLoad state if it's there
        const failedToLoad = removeObjectFromArray(state.auditHistory.failedToLoad, action.payload, null);

        return { ...state, auditHistory: { ...state.auditHistory, loading, loaded, failedToLoad } };
      })
    );
  }
  if (actions.loadObjectHistorySuccess) {
    onArray.push(
      on(actions.loadObjectHistorySuccess, (state: ISafariObjectState<T>, action: LoadObjectHistoryActionSuccessInfo) => {
        checkActionPayload(action, logger);
        const noAutoSwapFlag = action.originalOptions?.noAutoSwap;
        const loadedOrFailed = { ...action.payload, context: action.context, correlationId: action.correlationId, actionId: action.actionId };

        // Add this object to loaded state ONLY if we're chose not to autoswap (default behavior is to autoswap)
        // This can be used for backbuffering, so for example, waiting for object to load, then listening on it
        // and then swapping only if the object is not dirty or something like that
        const loaded = noAutoSwapFlag ? modifyObjectInArray(state.auditHistory.loaded, loadedOrFailed, action.originalPayload) : state.auditHistory.loaded;
        // The opposite is true for this one. If we chose not to autoswap the current will remain as is and we'll need to invoke
        // the swap action to switch them. Otherwise if we're autoswapping (default) this payload goes straight into the current
        const current = noAutoSwapFlag ? state.auditHistory.current : modifyObjectInArray(state.auditHistory.current, loadedOrFailed, action.originalPayload);
        // Remove this object from loading state
        const loading = removeObjectFromArray(state.auditHistory.loading, action.payload, action.originalPayload);

        return { ...state, auditHistory: { ...state.auditHistory, loading, loaded, current } };
      })
    );
  }
  if (actions.loadObjectHistoryFail) {
    onArray.push(
      on(actions.loadObjectHistoryFail, (state: ISafariObjectState<T>, action: ActionErrorBase<{ id: SafariObjectId }>) => {
        checkActionPayload(action, logger);
        // Remove this object from loading state
        const loading = removeObjectFromArray(state.auditHistory.loading, action.payload, action.originalPayload);
        const failed = { ...action.payload, context: action.context, correlationId: action.correlationId, actionId: action.actionId };
        // Add this object to failedToload state
        const failedToLoad = modifyObjectInArray(state.auditHistory.failedToLoad, failed, action.originalPayload);

        return { ...state, auditHistory: { ...state.auditHistory, loading, failedToLoad } };
      })
    );
  }
  if (actions.clearObjectError) {
    onArray.push(
      on(actions.clearObjectError, (state: ISafariObjectState<T>, action: ClearObjectErrorActionInfo) => {
        let failedToLoad = [];
        let failedToSave = [];
        let failedToDelete = [];
        // If we want a specific object error to be cleared then an ID will have been sent to this function.
        // Otherwise all object errors will be cleared
        if (action.id != null) {
          failedToLoad = removeObjectFromArray(state.object.failedToLoad, { id: action.id }, null);
          failedToSave = removeObjectFromArray(state.object.failedToSave, { id: action.id }, null);
          failedToDelete = removeObjectFromArray(state.object.failedToDelete, { id: action.id }, null);
        }
        return { ...state, object: { ...state.object, failedToLoad, failedToSave, failedToDelete } };
      })
    );
  }
  if (actions.moveLoadedToCurrent) {
    onArray.push(
      on(actions.moveLoadedToCurrent, (state: ISafariObjectState<T>, action: { payload: MoveLoadedToCurrentActionInfo }) => {
        checkActionPayload(action, logger);

        const current = modifyObjectInArray(
          state.object.current,
          state.object.loaded.find(o => o.id == action.payload.id),
          null
        );
        const loaded = removeObjectFromArray(state.object.loaded, { id: action.payload.id }, null);
        return { ...state, object: { ...state.object, current, loaded } };
      })
    );
  }
  if (actions.moveLoadedListToCurrent) {
    onArray.push(
      on(actions.moveLoadedListToCurrent, (state: ISafariObjectState<T>, action: { payload: MoveLoadedListToCurrentActionInfo }) => {
        checkActionPayload(action, logger);

        const current = modifyObjectInArray(
          state.list.current,
          state.list.loaded.find(o => o.id == action.payload.id),
          null
        );
        const loaded = removeObjectFromArray(state.list.loaded, { id: action.payload.id }, null);
        return { ...state, list: { ...state.list, current, loaded } };
      })
    );
  }
  if (actions.updateObjectList) {
    onArray.push(
      on(actions.updateObjectList, (state: ISafariObjectState<T>, action: UpdateObjectListActionInfo<T>) => {
        if (action.abort) {
          // When returning state make sure to remove everything from loading, otherwise
          // the state will look incorrect (items aborted will end up getting stuck in "loading" array -
          // may not cause any issues by itself but it's weird)
          return {
            ...state,
            ...{
              list: {
                ...state.list,
                saving: []
              }
            }
          };
        }
        checkActionPayload(action, logger);
        // Add this list to loading state
        const saving = modifyObjectInArray(state.list.saving, action.payload as ObjectWithActionInfo, null, true, false, true);
        // Remove the list from loaded state if it's there
        const saved = removeObjectFromArray(state.list.saved, action.payload as ObjectWithActionInfo, null, true);
        // Remove the list from failedToLoad state if it's there
        const failedToSave = removeObjectFromArray(state.list.failedToSave, action.payload as ObjectWithActionInfo, null, true);
        return { ...state, list: { ...state.list, ...{ saving, saved, failedToSave } } };
      })
    );
  }
  if (actions.updateObjectListSuccess) {
    onArray.push(
      on(actions.updateObjectListSuccess, (state: ISafariObjectState<T>, action: UpdateObjectListActionSuccessInfo<T>) => {
        checkActionPayload(action, logger);
        const noAutoSwapFlag = action.originalOptions?.noAutoSwap;
        // Add this object to saved state ONLY if we're chose not to autoswap (default behavior is to autoswap)
        // This can be used for backbuffering, so for example, waiting for object to load, then listening on it
        // and then swapping only if the object is not dirty or something like that
        const currentOrSaved = { ...action.payload, context: action.context, correlationId: action.correlationId, actionId: action.actionId };

        const saved = noAutoSwapFlag ? modifyObjectInArray(state.list.saved, currentOrSaved, null, true, false, true) : state.list.saved;

        // The opposite is true for this one. If we chose not to autoswap the current will remain as is and we'll need to invoke
        // the swap action to switch them. Otherwise if we're autoswapping (default) this payload goes straight into the current
        const current = noAutoSwapFlag ? state.list.current : modifyObjectInArray(state.list.current, currentOrSaved, null, true, false, true);

        // Remove the list from saving state if it's there
        const saving = removeObjectFromArray(state.list.saving, action.payload, null, true);
        // Add this list to loading state
        //

        return { ...state, list: { ...state.list, ...{ saving, saved, current } } };
      })
    );
  }

  if (actions.updateObjectListFail) {
    onArray.push(
      on(actions.updateObjectListFail, (state: ISafariObjectState<T>, action: UpdateObjectListActionFailInfo<T>) => {
        checkActionPayload(action, logger);
        // Remove the list from loaded state if it's there
        const saving = removeObjectFromArray(state.list.saving, action.payload as ObjectWithActionInfo, null, true);
        // Add this list to loading state

        const failed = { ...action.payload, context: action.context, correlationId: action.correlationId, actionId: action.actionId } as ObjectWithActionInfo;
        const failedToSave = modifyObjectInArray(state.list.failedToSave, failed, null, true, false, true);
        return { ...state, list: { ...state.list, ...{ saving, failedToSave } } };
      })
    );
  }

  onArray.push(on(purgeAllObjectErrors, (state: ISafariObjectState<T>, action: any) => ({ ...state, object: { ...state.object, failedToSave: [], failedToDelete: [], failedToLoad: [] } })));
  onArray.push(
    on(clearAllObjectStates, (state: ISafariObjectState<T>, action: any) => ({
      ...state,
      ...reduxObjectInitState
    }))
  );

  if (additionalReducers) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- we just don't know what this will be. Allow any
    additionalReducers(onArray);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- we just don't know what this will be. Allow any
  return createReducer(defaultState, ...onArray);
};
export const createDropdownReducerFromFunctionArray = <T>(defaultState: T, array: DefaultDropdownActionConverter, actionEnum: { [key: string]: SafariObjectId; [value: number]: SafariObjectId }) => {
  const onArray = [];

  if (array.clearDropdown) {
    onArray.push(
      on(array.clearDropdown, (state: IDropdownState, action: DropdownIdActionInfo) => {
        const obj = {};

        obj[SafariObject.id(action.id).toLowerCase()] = null;
        return { ...state, ...obj };
      })
    );
  }
  if (array.loadDropdownSuccess) {
    onArray.push(
      on(array.loadDropdownSuccess, (state: IDropdownState, action: DropdownLoadSuccessActionInfo) => {
        const obj = {};
        obj[SafariObject.id(action.id).toLowerCase()] = [...action.dropdownValues];
        return { ...state, ...obj };
      })
    );
  }
  if (array.loadDropdownFail) {
    onArray.push(on(array.loadDropdownFail, (state: IDropdownState) => ({ ...state })));
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- we just don't know what this will be. Allow any
  return createReducer(defaultState, ...onArray);
};
export const createSearchReducerFromFunctionArray = <T>(defaultState: T, array: DefaultSearchActionConverter) => {
  const onArray = [];

  if (array.clearSearch) {
    onArray.push(
      on(array.clearSearch, (state: ISearchState, action: DropdownIdActionInfo) => {
        const propName = action.id.toString().toLowerCase();

        const obj = {};

        obj[propName] = [];
        return { ...state, ...obj };
      })
    );
  }
  if (array.loadSearchSuccess) {
    onArray.push(
      on(array.loadSearchSuccess, (state: ISearchState, action: LoadSearchActionSuccessInfo) => {
        const propName = action.id.toString().toLowerCase();
        const obj = {};
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- we just don't know what searchResults will be. Allow any
        obj[propName] = [...action.searchResult];
        return { ...state, ...obj };
      })
    );
  }
  if (array.loadSearchFail) {
    onArray.push(on(array.loadSearchFail, (state: ISearchState) => ({ ...state })));
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- we just don't know what this will be. Allow any
  return createReducer(defaultState, ...onArray);
};
export const createFileUploadReducerFromFunctionArray = (defaultState, array: DefaultFileActionConverter) => {
  const onArray = [];

  if (array.cancelTransfer) {
    onArray.push(
      on(array.cancelTransfer, (state: IFileUploadState) => {
        const files = state.files;
        const cancelledFiles = [];
        for (const file of files) {
          const cancelledFile = {
            ...file,
            ...{
              percentComplete: 100,
              isError: true,
              message: 'Cancelled'
            }
          };
          cancelledFiles.push(cancelledFile);
        }
        return { ...state, files: cancelledFiles };
      })
    );
    onArray.push(
      on(cancelAllFiles, (state: IFileUploadState) => {
        const files = state.files;
        const cancelledFiles = [];
        for (const file of files) {
          const cancelledFile = {
            ...file,
            ...{
              percentComplete: 100,
              isError: true,
              message: 'Cancelled'
            }
          };
          cancelledFiles.push(cancelledFile);
        }
        return { ...state, files: cancelledFiles };
      })
    );
  }
  if (array.updateFileUploadProgress) {
    onArray.push(
      on(array.updateFileUploadProgress, (state: IFileUploadState, action: { payload: FileOperationInfo }) => {
        const files = modifyObjectInArray(state.files, action.payload, null);
        return { ...state, files };
      })
    );
  }
  if (array.processFileSuccess) {
    onArray.push(
      on(array.processFileSuccess, (state: IFileUploadState, action: { payload: FileOperationInfo }) => {
        const files = modifyObjectInArray(state.files, action.payload, null);
        return { ...state, files };
      })
    );
  }
  if (array.processFileFail) {
    onArray.push(
      on(array.processFileFail, (state: IFileUploadState, action: { payload: FileOperationInfo }) => {
        const files = modifyObjectInArray(state.files, action.payload, null);
        return { ...state, files };
      })
    );
  }
  if (array.clearFileInfoFromStore) {
    onArray.push(
      on(array.clearFileInfoFromStore, (state: IFileUploadState, action: { id: string }) => {
        const files = action.id == '0' ? [] : state.files.filter(o => o.parentId != action.id);
        return { ...state, files };
      })
    );
    onArray.push(
      on(clearAllFileStores, (state: IFileUploadState, action: { id: string }) => {
        const files = action.id == '0' ? [] : state.files.filter(o => o.parentId != action.id);
        return { ...state, files };
      })
    );
  }
  if (array.updateFileDownloadProgress) {
    onArray.push(
      on(array.updateFileDownloadProgress, (state: IFileUploadState, action: { payload: FileOperationInfo }) => {
        const files = modifyObjectInArray(state.files, action.payload, null);
        return { ...state, files };
      })
    );
  }
  if (array.previewFile) {
    onArray.push(on(array.previewFile, (state: IFileUploadState, action: { payload: FilePreviewRequest }) => ({ ...state, filePreview: action.payload != null ? { ...action.payload } : null })));
  }
  if (array.previewFileClosed) {
    onArray.push(
      on(array.previewFileClosed, (state: IFileUploadState, action: { payload: FilePreviewResponse }) => ({
        ...state,
        filePreviewResponse: { ...action.payload, __responseType: FilePreviewResponseType.Closed }
      }))
    );
  }
  if (array.previewFileEdit) {
    onArray.push(
      on(array.previewFileEdit, (state: IFileUploadState, action: { payload: FilePreviewResponse }) => ({
        ...state,
        filePreviewResponse: { ...action.payload, __responseType: FilePreviewResponseType.Edit }
      }))
    );
  }

  if (array.prepareForBulkTransfer) {
    onArray.push(
      on(array.prepareForBulkTransfer, (state: IFileUploadState, action) => {
        const filesToAdd = action.payload.filesToAdd
          ? action.payload.filesToAdd.map(o => ({
              ...o,
              ...{
                fileOperationType: o['fileOperationType'] == null ? FileOperationType.Add : o['fileOperationType'],
                percentComplete: -100,
                isError: false,
                message: 'Queuing',
                totalProcessed: 0,
                totalSize: o.file != null ? o.file.size : 0,
                isMetadataUpdate: o.file == null
              }
            }))
          : [];
        const filesToRemove = action.payload.filesToRemove
          ? action.payload.filesToRemove.map(o => ({ ...o, ...{ fileOperationType: FileOperationType.Remove, percentComplete: -100, isError: false, message: 'Queuing' } }))
          : [];
        // TODO: Once we have ability to download files in bulk we need to add that here
        const bulkFiles = [...filesToAdd, ...filesToRemove];
        // It would be easy enough to just overwrite state.files with bulkFiles above but then
        // it would blast away any files already in store (which could happen if we were to issue two individual
        // bulk actions while download is still pending. We don't have that scenario, and maybe never will but the
        // code below should support it)

        let files = state.files;
        for (const file of bulkFiles) {
          files = modifyObjectInArray(files, file, null);
        }
        return { ...state, files };
      })
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- we just don't know what this will be. Allow any
  return createReducer(defaultState, ...onArray);
};
