import { HttpClient } from '@angular/common/http';
import { cloneDeep } from 'lodash-es';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import {
  AppConfigurationAuth,
  AppConfigurationAuth0,
  AppConfigurationBootstrap,
  AppConfigurationErrorHandling,
  AppConfigurationInterface,
  AppConfigurationLoggers,
  AppConfigurationSignalR,
  AppConfigurationStatusApp,
  AppConfigurationStripe,
  AppConfigurationUiSettings,
  AppConfigurationUrls
} from './appconfiguration';
import { AppConfigurationFeatureFlags } from './appconfiguration-feature-flags';

export class AppConfigurationService implements AppConfigurationInterface {
  private readonly appconfigkey = 'safari_appconfig';
  _injectedAppConfig: AppConfigurationInterface;
  injectConfiguration() {
    // To be overridden in child classes
  }
  getSortedConfigs(configs: AppConfigurationInterface[]) {
    return configs.ssort(
      o => o.hostname,
      (a: string, b: string) => {
        // Note that this is a reverse sort, from most periods to least, then reverse alphabetical.
        // This ensures that the "endsWith" matches the highest specificity hostnames first.
        const periodCompare = [...b.matchAll(/\./g)].length - [...a.matchAll(/\./g)].length;
        if (!periodCompare) {
          if (a.length < b.length) return 1;
          if (a.length > b.length) return -1;

          if (a < b) return 1;
          if (a > b) return -1;
          return 0;
        }
        return periodCompare;
      }
    );
  }

  get urlHostname(): string {
    return window.location.hostname.toLowerCase();
  }

  getCorrectEnvironment(configs: AppConfigurationInterface[]) {
    const hostname = this.urlHostname;
    const sortedConfigs = this.getSortedConfigs(configs);
    const config = sortedConfigs.find(c => hostname.endsWith(c.hostname));

    if (!config) {
      throw new Error('unknown configuration hostname: ' + hostname);
    }
    // eslint-disable-next-line no-console -- We want to keep this console.log
    console.log('Init: Applying configuration');

    return cloneDeep(config);
  }

  public overrideConfiguration(): Observable<boolean> {
    // We currently only override dev environment. However this function
    // could also be used to retrieve partial settings (feature flag overrides)
    // over http as well, if we wanted to.
    if (this.name == 'development') {
      return this._httpClient.get('appconfiguration/appconfiguration.dev.json').pipe(
        catchError(err => throwError(() => new Error('Development environment must have a .dev file'))),
        map(o => {
          if (Object.keys(o).find(key => key == 'machineName') == null) {
            throw new Error('.dev file must define machineName override');
          }
          if (Object.keys(o).length > 0) {
            // eslint-disable-next-line no-console -- We want to keep this console.log
            console.log('Init: Dev config override', o);
            this._injectedAppConfig = { ...this._injectedAppConfig, ...o };
          }
          return true;
        })
      );
    }

    return of(true);
  }
  constructor(protected _httpClient: HttpClient) {
    this.injectConfiguration();
  }

  get name(): string {
    return this._injectedAppConfig.name;
  }
  get machineName(): string {
    return this._injectedAppConfig.machineName || '';
  }
  get hostname(): string {
    return this._injectedAppConfig.hostname;
  }
  get development(): boolean {
    return this._injectedAppConfig.development;
  }
  get production(): boolean {
    return this._injectedAppConfig.production;
  }

  get applicationName(): string {
    return this._injectedAppConfig.applicationName;
  }

  get sourceVersion(): string {
    return this._injectedAppConfig.sourceVersion;
  }

  get urls(): AppConfigurationUrls {
    const values = cloneDeep(this._injectedAppConfig.urls);
    const subdomain = this.urlHostname.split('.')[0];
    for (const key of Object.keys(values)) {
      values[key] = values[key].replace('@', subdomain);
    }
    return values;
  }

  get auth0(): AppConfigurationAuth0 {
    return cloneDeep(this._injectedAppConfig.auth0);
  }
  get auth(): AppConfigurationAuth {
    return cloneDeep(this._injectedAppConfig.auth);
  }
  get statusApp(): AppConfigurationStatusApp {
    const values = cloneDeep(this._injectedAppConfig.statusApp);
    const subdomain = this.urlHostname.split('.')[0];
    if (values?.apiRootUrl) {
      values.apiRootUrl = values.apiRootUrl.replace('@', subdomain);
    }
    return values;
  }
  get errors(): AppConfigurationErrorHandling {
    return cloneDeep(this._injectedAppConfig.errors);
  }
  get featureFlags(): AppConfigurationFeatureFlags {
    return cloneDeep(this._injectedAppConfig.featureFlags);
  }
  get stripe(): AppConfigurationStripe {
    return cloneDeep(this._injectedAppConfig.stripe);
  }
  get loggers(): AppConfigurationLoggers {
    return cloneDeep(this._injectedAppConfig.loggers);
  }
  get uiSettings(): AppConfigurationUiSettings {
    return cloneDeep(this._injectedAppConfig.uiSettings);
  }

  get bootstrap(): AppConfigurationBootstrap {
    return cloneDeep(this._injectedAppConfig.bootstrap);
  }
  get signalR(): AppConfigurationSignalR {
    return cloneDeep(this._injectedAppConfig.signalR);
  }
}
