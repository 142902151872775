import { Injectable } from '@angular/core';
import { ApiCallContext, IAddress, IdName, SafariBulkObject, SafariInjector, SafariObject, SafariObjectId } from '@safarilaw-webapp/shared/common-objects-models';
import { DateTime } from 'luxon';

export class PatchObject {
  patchCommands: any[];
  id: SafariObjectId;
  __etag: string;
}

export type NoHiddenProps<T> = Omit<T, 'error' | 'actionId' | '__etag' | '__priorId' | '__base' | 'context'>;
/**
 * Adapter specific model that forces all properties to be entered.
 * Also excludes reserved property names such as error, etag, etc. If it so happens that one of the future
 * API models has a legitmate property called "error" in its definition, your object will have to define something different like
 * "apiError" or something like that.
 *
 */
export type SafariRequired<T> = Required<NoHiddenProps<T>>;

export class ApiDropdownAdapter extends SafariInjector {
  fromListModel(model: IdName, context: ApiCallContext = null): SafariRequired<IdName> {
    return {
      id: model.id,
      isActive: model.isActive == null ? true : model.isActive,
      name: model.name,
      order: model.order == null ? 0 : model.order
    };
  }
}

export abstract class ApiDataAdapter<T> {
  private _getDateForApi(isoDate: string | Date, ignoreTime: boolean = true): string {
    if (isoDate == null) {
      return null;
    }

    let date: DateTime;
    if (typeof isoDate === 'string') {
      date = DateTime.fromISO(isoDate);
    } else {
      date = DateTime.fromJSDate(isoDate);
    }

    if (ignoreTime) {
      // All user entered dates are in local time, but we don't care about the time component.
      // As such, do not convert to UTC and risk modifying the entered date; Instead this works like a truncation
      return `${date.toFormat("yyyy'-'MM'-'dd")}`;
    } else {
      return date.toUTC().toFormat("yyyy'-'MM'-'dd'T'HH':'mm':'ssZZ");
    }
  }
  private _getDateFromApi(date: string, ignoreTime: boolean = true): string {
    if (date == null) {
      return null;
    }

    if (ignoreTime) {
      // All user entered dates are in local time, but we don't care about the time component.
      // As such, do not convert to UTC and risk modifying the entered date; Instead this works like a truncation
      return DateTime.fromISO(date.substring(0, 10)).toISO();
    } else {
      // The API will include any time components with timezone if they're needed, and they'll be converted to local time automatically.
      return DateTime.fromISO(date).toISO();
    }
  }

  helpers = {
    toApiDate: (date: string | Date, ignoreTime: boolean = true): string => this._getDateForApi(date, ignoreTime),
    fromApiDate: (date: string, ignoreTime: boolean = true): string => this._getDateFromApi(date, ignoreTime),
    // API will never have a compound string so we're always going to call idObjectOnly when sending to the API
    // Note: don't let idObjectOnly run on NULL because it normalizes null to '', so let's do null check first
    toApiId: (id: SafariObjectId): number => (id == null ? null : (SafariObject.idObjectOnly(id) as unknown as number)),

    fromApiId: (id: string | number | string[] | number[], parentId: SafariObjectId = null): string => {
      // We probably shouldn't have to bail out if NULL because SafariObject.id below will normalize it to '', but
      // I m worried that some code around might do checks like id == null which won't work if we normalize to ''
      // (how i wish we had operator overloading...)
      // So for now to make sure all backward-compat is kept we'll return NULL right away if what was passed in was NULL
      if (id == null) {
        return null;
      }

      return SafariObject.id(parentId, id);
    },
    fromApiAddress: (address: Partial<IAddress>): IAddress => {
      // This is conversion from the old logic but I wonder if we should just
      // be returning address with empty fields instead of null
      if (!address) {
        return null;
      }
      return {
        city: address.city,
        country: address.country,
        state: address.state,
        street1: address.street1,
        street2: address.street2,
        zipCode: address.zipCode
      };
    }
  };
  getPatchObject(formModel: Partial<T> & { id?: string; __etag?: string }): PatchObject {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- need to force partial to call toUpdateModel
    const obj = this.toUpdateModel(formModel as any);

    Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);

    const patchCommands: { op: string; path: string; value: any }[] = [];
    Object.keys(obj).forEach(key => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- we don't know what the value can be
      patchCommands.push({ op: 'add', path: '/' + key, value: obj[key] });
    });

    return {
      patchCommands,
      __etag: formModel['__etag'],
      id: formModel.id
    };
  }

  toCreateModel(formModel: T, context: ApiCallContext = null): SafariRequired<any> {
    throw new Error('Not implemented');
  }
  toUpdateModel(formModel: T, context: ApiCallContext = null): SafariRequired<any> {
    throw new Error('Not implemented');
  }
  toUpdateMultipleModel(formModel: SafariBulkObject<T>, context: ApiCallContext = null): SafariRequired<any> {
    throw new Error('Not implemented');
  }
  toCreateMultipleModel(formModel: SafariBulkObject<T>, context: ApiCallContext = null): SafariRequired<any> {
    throw new Error('Not implemented');
  }
  toUpdatePartialModel(formModel: Partial<T>, originalObject: Partial<T>, context: ApiCallContext = null): PatchObject {
    throw new Error('Not implemented');
    // NOTE: It might be tempting to copy code from getPatchObject and move it here since it's generic, but
    // we want children to explicitly define toUpdatePartialModel so that dynamic redux generation knows whether
    // to create the effect or not
  }

  toUpdateListModel(formModel: T, context: ApiCallContext = null): SafariRequired<any> {
    throw new Error('Not implemented');
  }
  fromGetModel(model: any, context: ApiCallContext = null): SafariRequired<T> {
    throw new Error('Not implemented');
  }
  fromGetNewModel(context: ApiCallContext = null): SafariRequired<T> {
    throw new Error('Not implemented');
  }
  fromListModel(model: any, context: ApiCallContext = null): SafariRequired<T> {
    throw new Error('Not implemented');
  }
  toDeleteModel(model: any): any {
    // Currently we do not support passing body in delete (mainly due to angular's http client limitations)
    // but in the future we might. However this is also used by auto-redux/service generators to find out
    // if the object can be deleted. If your object needs delete functionality just implement by returning null
    throw new Error('Not implemented');
  }
  toDeleteMultipleModel(objects: SafariBulkObject<T>): any {
    throw new Error('Not implemented');
  }
  fromHistory(model: any): any {
    // Currently we do not have a well defined history/audit model. Only matters use history. But we can use
    // this for future updates and also it will tell redux/service generators that this object needs load history
    // feature
  }
}
@Injectable({
  providedIn: 'root'
})
export class HelperObjectAdapterPOST extends ApiDataAdapter<SafariObject> {
  toCreateModel(formModel: SafariObject, context?: ApiCallContext): Required<any> {
    return null;
  }
}
@Injectable({
  providedIn: 'root'
})
export class HelperObjectAdapterPUT extends ApiDataAdapter<SafariObject> {
  toUpdateModel(formModel: SafariObject, context?: ApiCallContext): Required<any> {
    return null;
  }
}
