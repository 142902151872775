/* eslint-disable no-restricted-syntax -- framework use */
/**
 * THIS NEEDS TO BE EXPORTED SO THAT THE WEBAPP CAN REGISTER THE OBJECTS IT WANTS TO USE
 * IN ITS BASE PAGE
 */
import { inject, InjectionToken } from '@angular/core';

import { ISafariObjectState, SafariReduxApiObject, SafariReduxDropdownObject } from '@safarilaw-webapp/shared/redux';
import { User } from '../co-user/models/app/user';
import { CompanySettings } from '../company-settings/models/app/company-settings';
import { Company } from '../company/models/app/company';
import { InboundEmailSetting } from '../inbound-email-setting/models/app/inbound-email-setting';
import { NotificationPreference } from '../notification-preferences/models/app/notification-preference';
import { RegisteredAgentSettings } from '../registered-agent-settings/models/app/registered-agent-settings';
import { Team } from '../team/models/app/team';

import { createSelector } from '@ngrx/store';

import { DropdownType } from '../enums';

import { AttachmentLink, FileObject, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { MyCompanies } from '../my-companies/models/app/my-companies';
import {
  CO_USER_SIGNATURE_SERVICE_TOKEN,
  COMPANY_LOGO_FILE_OBJECT_SERVICE_TOKEN,
  DROPDOWN_SERVICE_TOKEN,
  GENERATE_REPORTS_ACCOUNTS_TEMPLINK_SERVICE_TOKEN,
  GENERATE_REPORTS_LOGINS_TEMPLINK_SERVICE_TOKEN,
  MY_COMPANIES_SERVICE_TOKEN,
  MY_COMPANY_SERVICE_TOKEN,
  MY_COMPANY_SETTINGS_SERVICE_TOKEN,
  MY_INBOUND_EMAIL_SETTINGS_SERVICE_TOKEN,
  MY_SIGNATURE_FILE_OBJECT_SERVICE_TOKEN,
  MY_USER_SERVICE_TOKEN,
  NOTIFICATION_PREFERENCE_SERVICE_TOKEN,
  REGISTERED_AGENT_SETTINGS_SERVICE_TOKEN,
  TEAM_SERVICE_TOKEN,
  USER_SERVICE_TOKEN,
  USER_WELCOME_EMAIL_SERVICE_TOKEN
} from './injectable-service-tokens';
import { getSharedFeatureState, ICoManageStateDropdown, PROJECT_NAME } from './state.interface';

export const COMPANY_SETTINGS_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<CompanySettings>, CompanySettings>>('COMPANY_SETTINGS_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<CompanySettings>, CompanySettings>(
      PROJECT_NAME,
      'CompanySettings', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MY_COMPANY_SETTINGS_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.companyCombinedState.companySettingsState)
    )
});
export const MY_INBOUND_EMAIL_SETTINGS_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<InboundEmailSetting>, InboundEmailSetting>>(
  'MY_INBOUND_EMAIL_SETTINGS_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<InboundEmailSetting>, InboundEmailSetting>(
        PROJECT_NAME,
        'InboundEmailSetting', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(MY_INBOUND_EMAIL_SETTINGS_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => state.companyCombinedState.inboundEmailSettingState)
      )
  }
);

export const REGISTERED_AGENT_SETTINGS_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<RegisteredAgentSettings>, RegisteredAgentSettings>>(
  'REGISTERED_AGENT_SETTINGS_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<RegisteredAgentSettings>, RegisteredAgentSettings>(
        PROJECT_NAME,
        'RegisteredAgentSettings', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(REGISTERED_AGENT_SETTINGS_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => state.companyCombinedState.registeredAgentSettingsState)
      )
  }
);
export const MY_COMPANY_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Company>, Company>>('MY_COMPANY_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Company>, Company>(
      PROJECT_NAME,
      'Company', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MY_COMPANY_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.companyCombinedState.companyState)
    )
});
export const NOTIFICATION_PREFERENCES_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<NotificationPreference>, NotificationPreference>>(
  'NOTIFICATION_PREFERENCES_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<NotificationPreference>, NotificationPreference>(
        PROJECT_NAME,
        'NotificationPreference', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(NOTIFICATION_PREFERENCE_SERVICE_TOKEN),
        createSelector(getSharedFeatureState, state => state.notificationPreferenceCombinedState.notificationPreferenceState)
      )
  }
);
export const TEAM_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<Team>, Team>>('TEAM_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<Team>, Team>(
      PROJECT_NAME,
      'Team', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(TEAM_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.teamCombinedState.teamState)
    )
});

export const USER_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<User>, User>>('USER_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<User>, User>(
      PROJECT_NAME,
      'User', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(USER_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.userCombinedState.userState)
    )
});
export const USER_SIGNATURE_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>>('USER_SIGNATURE_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>(
      PROJECT_NAME,
      'UserSignature', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(CO_USER_SIGNATURE_SERVICE_TOKEN),
      SafariObject.EMPTY,
      null
    )
});

export const DROPDOWN_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxDropdownObject<ICoManageStateDropdown>>('DROPDOWN_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxDropdownObject<ICoManageStateDropdown>(
      PROJECT_NAME,
      inject(DROPDOWN_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.dropdownCombinedState.dropdownState),
      DropdownType,
      {
        countries: null,
        states: null,
        timezones: null,
        userpermissions: null,
        applications: null,
        informationRequestTypes: null
      }
    )
});
export const MY_USER_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<User>, User>>('MY_USER_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<User>, User>(
      PROJECT_NAME,
      'MyUser', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MY_USER_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.myCombinedState.userState)
    )
});

export const MY_COMPANIES_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<MyCompanies>, MyCompanies>>('MY_COMPANIES_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<MyCompanies>, MyCompanies>(
      PROJECT_NAME,
      'MyCompanies', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MY_COMPANIES_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => state.myCombinedState.companiesState)
    )
});
export const USER_WELCOME_EMAIL_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>>('CONFIRM_RECEIPT_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<SafariObject>, SafariObject>(
      PROJECT_NAME,
      'SendNewUserEmail', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(USER_WELCOME_EMAIL_SERVICE_TOKEN),
      SafariObject.EMPTY,
      null
    )
});
export const GENERATE_REPORTS_ACCOUNTS_TEMPLINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>(
  'GENERATE_REPORTS_ACCOUNTS_TEMPLINK_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
        PROJECT_NAME,
        'Reports Accounts Link', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(GENERATE_REPORTS_ACCOUNTS_TEMPLINK_SERVICE_TOKEN),
        SafariObject.EMPTY
      )
  }
);
export const GENERATE_REPORTS_LOGINS_TEMPLINK_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>>(
  'GENERATE_REPORTS_LOGINS_TEMPLINK_REDUX_OBJECT_TOKEN',
  {
    providedIn: 'root',
    factory: () =>
      new SafariReduxApiObject<ISafariObjectState<AttachmentLink>, AttachmentLink>(
        PROJECT_NAME,
        'Reports Logins Link', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
        inject(GENERATE_REPORTS_LOGINS_TEMPLINK_SERVICE_TOKEN),
        SafariObject.EMPTY
      )
  }
);
export const MY_SIGNATURE_FILE_OBJECT_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>>('MY_SIGNATURE_FILE_OBJECT_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>(
      PROJECT_NAME,
      'MySignatureFileObject', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(MY_SIGNATURE_FILE_OBJECT_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => SafariObject.EMPTY)
    )
});
export const COMPANY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN = new InjectionToken<SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>>('COMPANY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN', {
  providedIn: 'root',
  factory: () =>
    new SafariReduxApiObject<ISafariObjectState<FileObject>, FileObject>(
      PROJECT_NAME,
      'CompanyLogoFileObject', // <--- NEEDS TO BE UNIQUE IN THIS FILE - USED TO GENERATE UNIQUE ACTION NAME
      inject(COMPANY_LOGO_FILE_OBJECT_SERVICE_TOKEN),
      createSelector(getSharedFeatureState, state => SafariObject.EMPTY)
    )
});
/*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS ***/
