/* eslint-disable no-restricted-syntax -- framework use*/
import { InjectionToken, inject } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EffectWithIdentifier, GenericDropdownEffects } from '@safarilaw-webapp/shared/redux';
import { User } from '../co-user/models/app/user';
import { Company } from '../company/models/app/company';
import { Team } from '../team/models/app/team';

import { AttachmentLink, FileObject, SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { MyCompanies } from '../my-companies/models/app/my-companies';
import { NotificationPreference } from '../notification-preferences/models/app/notification-preference';
import {
  COMPANY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN,
  COMPANY_SETTINGS_REDUX_OBJECT_TOKEN,
  DROPDOWN_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_ACCOUNTS_TEMPLINK_REDUX_OBJECT_TOKEN,
  GENERATE_REPORTS_LOGINS_TEMPLINK_REDUX_OBJECT_TOKEN,
  MY_COMPANIES_REDUX_OBJECT_TOKEN,
  MY_COMPANY_REDUX_OBJECT_TOKEN,
  MY_INBOUND_EMAIL_SETTINGS_REDUX_OBJECT_TOKEN,
  MY_SIGNATURE_FILE_OBJECT_REDUX_OBJECT_TOKEN,
  MY_USER_REDUX_OBJECT_TOKEN,
  NOTIFICATION_PREFERENCES_REDUX_OBJECT_TOKEN,
  REGISTERED_AGENT_SETTINGS_REDUX_OBJECT_TOKEN,
  TEAM_REDUX_OBJECT_TOKEN,
  USER_REDUX_OBJECT_TOKEN,
  USER_SIGNATURE_REDUX_OBJECT_TOKEN,
  USER_WELCOME_EMAIL_REDUX_OBJECT_TOKEN
} from './injectable-object-tokens';
import { ICoManageStateDropdown } from './state.interface';

export const getEffectTokens = () => [
  new InjectionToken<EffectWithIdentifier<Company>>('MY_COMPANY_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () =>
      new EffectWithIdentifier<Company>(inject(Store), inject(Actions), inject(MY_COMPANY_REDUX_OBJECT_TOKEN), [
        inject(COMPANY_SETTINGS_REDUX_OBJECT_TOKEN),
        inject(MY_INBOUND_EMAIL_SETTINGS_REDUX_OBJECT_TOKEN),
        inject(REGISTERED_AGENT_SETTINGS_REDUX_OBJECT_TOKEN)
      ])
  }),
  new InjectionToken<EffectWithIdentifier<User>>('USER_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<User>(inject(Store), inject(Actions), inject(USER_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<User>>('MY_USER_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<User>(inject(Store), inject(Actions), inject(MY_USER_REDUX_OBJECT_TOKEN))
  }),

  new InjectionToken<GenericDropdownEffects<ICoManageStateDropdown>>('DROPDOWN_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new GenericDropdownEffects<ICoManageStateDropdown>(inject(Actions), inject(Store), inject(DROPDOWN_REDUX_OBJECT_TOKEN), [], null)
  }),
  new InjectionToken<EffectWithIdentifier<NotificationPreference>>('NOTIFICATION_PREFERENCE_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<NotificationPreference>(inject(Store), inject(Actions), inject(NOTIFICATION_PREFERENCES_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<SafariObject>>('USER_WELCOME_EMAIL_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<SafariObject>(inject(Store), inject(Actions), inject(USER_WELCOME_EMAIL_REDUX_OBJECT_TOKEN))
  }),

  new InjectionToken<EffectWithIdentifier<Team>>('TEAM_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<Team>(inject(Store), inject(Actions), inject(TEAM_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<MyCompanies>>('MY_COMPANIES_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<MyCompanies>(inject(Store), inject(Actions), inject(MY_COMPANIES_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('GENERATE_REPORTS_ACCOUNTS_TEMPLINK_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(GENERATE_REPORTS_ACCOUNTS_TEMPLINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<AttachmentLink>>('GENERATE_REPORTS_LOGINS_TEMPLINK_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<AttachmentLink>(inject(Store), inject(Actions), inject(GENERATE_REPORTS_LOGINS_TEMPLINK_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<FileObject>>('USER_SIGNATURE_REDUX_EFFECT', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<FileObject>(inject(Store), inject(Actions), inject(USER_SIGNATURE_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<FileObject>>('MY_SIGNATURE_FILE_OBJECT_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<FileObject>(inject(Store), inject(Actions), inject(MY_SIGNATURE_FILE_OBJECT_REDUX_OBJECT_TOKEN))
  }),
  new InjectionToken<EffectWithIdentifier<FileObject>>('COMPANY_LOGO_FILE_OBJECT_REDUX_EFFECT_TOKEN', {
    providedIn: 'root',
    factory: () => new EffectWithIdentifier<FileObject>(inject(Store), inject(Actions), inject(COMPANY_LOGO_FILE_OBJECT_REDUX_OBJECT_TOKEN))
  })
  /*** BEWARE OF DOG - DO NOT DELETE OR PUT ANYTHING ELSE BELOW THIS ***/
];
