/* eslint-disable @typescript-eslint/no-unsafe-return -- low level file */
/* eslint-disable @typescript-eslint/no-unsafe-call -- low level file */
/* eslint-disable @typescript-eslint/no-unsafe-argument -- low level file  */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- low level file */
/* eslint-disable @typescript-eslint/no-unsafe-call -- low level file */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- low level file */
import { Action, ActionCreator, ActionReducer, createAction, createReducer, createSelector, DefaultProjectorFn, MemoizedSelector, on, props, Selector } from '@ngrx/store';
import { ActionCreatorProps, ActionType, NotAllowedCheck } from '@ngrx/store/src/models';
import {
  ActionInfoBase,
  DefaultFileActionConverter,
  DefaultFileSelectorConverter,
  FileDownloadRequest,
  FileOperationActions,
  FilePreviewCloseRequest,
  FilePreviewRequest,
  IdName,
  IReducerErrorLogger,
  PageUiAction,
  populateDefaultFileActions,
  RouterNavigationInfo,
  SafariObject,
  SafariObjectId,
  SafariReduxFileTransferObjectDefinition
} from '@safarilaw-webapp/shared/common-objects-models';
// this is just due to a service.spec.ts. We'll have to figure out how to change that test
// so it doesn't refer back to this file, but for now, it's ok to leave it as-is. Don't care too much about enforcing test lintability
// eslint-disable-next-line @nx/enforce-module-boundaries -- comments above
import { CrudService, DropdownService } from '@safarilaw-webapp/shared/crud';
import { cloneDeep } from 'lodash-es';

import { DefaultDropdownActionConverter, populateDefaultDropdownActions } from './actions/dropdown';

import { DropdownActionTypes, ObjectActionTypes } from './actions/enums';
import { DefaultActionConverter, populateDefaultActions } from './actions/object';
import { DefaultSearchActionConverter, populateDefaultSearchActions, SearchActionTypes } from './actions/search';
import { ObjectFileTransferServiceBase } from './effects';
import { reduxObjectInitState } from './initial';
import { IDropdownState, IFileUploadState, ISafariObjectState, ISearchState } from './interface';
import {
  createDropdownReducerFromFunctionArray,
  createFileUploadReducerFromFunctionArray,
  createReducerFromFunctionArray,
  createSearchReducerFromFunctionArray,
  modifyObjectInArray,
  ObjectWithActionInfo,
  removeObjectFromArray
} from './reducer';
import { createGetDropdownStateSelector, DefaultDropdownSelectorConverter } from './selectors/dropdown';
import { createDefaultFileOperationSelectors } from './selectors/file';
import { createDefaultSelectors, DefaultSelectorConverter } from './selectors/object';
import { createGetSearchStateSelector, DefaultSearchSelectorConverter } from './selectors/search';
export { PageUiAction } from '@safarilaw-webapp/shared/common-objects-models';
/**
 * This is our "hack" over NGRX OnReducer.
 * Originally in our addState I used their OnReducer, but it was cauing issues with type inferrence
 * (specifically with LpmsMatterEdit which is a base page that passes type S extends IMatterPageUiState. It worked
 * fine with pages that define direct type without further child overrides)
 * The original NGRX reducer couldn't figure out the type and was returning S extends unknown in that case,
 * probably coz it is too generic with its type inferrence.
 *
 * So I had to create my own version of it. It's a bit more strict, but it works for our purposes
 */
type OnReducer2<State, Creators extends readonly ActionCreator[]> = (state: State, action: ActionType<Creators[number]>) => State;
export enum FromActionBehavior {
  Default,
  NoNullExpansion
}
export const safariReduxFileTransferGenerator = (projectName: string) => new SafariReduxFileTransferGenerator(projectName);
class SafariReduxFileTransferGenerator {
  private _defaultActionMap: Map<FileOperationActions, string> = new Map();
  private _reducer: ActionReducer<any, Action> = null;
  private _defaultState: IFileUploadState = null;
  private _defaultSelectors: DefaultFileSelectorConverter;
  private _fileActions: DefaultFileActionConverter;
  constructor(private _projectName: string) {}

  addFileTransfer() {
    this._defaultActionMap.set(FileOperationActions.CancelTransfer, `[${this._projectName}] File Transfer: Cancel`);
    this._defaultActionMap.set(FileOperationActions.UpdateFileUploadProgress, `[${this._projectName}] File Transfer: Upload Progress`);
    this._defaultActionMap.set(FileOperationActions.ProcessFileFail, `[${this._projectName}] File Transfer: Fail`);
    this._defaultActionMap.set(FileOperationActions.ProcessFileSuccess, `[${this._projectName}] File Transfer: Success`);
    this._defaultActionMap.set(FileOperationActions.ClearFileInfoFromStore, `[${this._projectName}] File Transfer: Clear All Info`);
    this._defaultActionMap.set(FileOperationActions.UpdateFileDownloadProgress, `[${this._projectName}] File Transfer: Download Progress`);
    this._defaultActionMap.set(FileOperationActions.PrepareForBulkTransfer, `[${this._projectName}] File Transfer: Bulk Upload`);
    return this;
  }
  addFilePreview() {
    // must add transfer for preview
    this.addFileTransfer();
    this._defaultActionMap.set(FileOperationActions.PreviewFile, `[${this._projectName}] Preview File: Request`);
    this._defaultActionMap.set(FileOperationActions.PreviewFileClosed, `[${this._projectName}] Preview File: Closed`);
    this._defaultActionMap.set(FileOperationActions.PreviewFileEdit, `[${this._projectName}] Preview File: Edit`);
    return this;
  }
  addAllActions() {
    // right now this is as same as addfilepreview
    this.addFilePreview();
    return this;
  }
  addState(defaultState: IFileUploadState) {
    this._defaultState = defaultState;
    return this;
  }
  addSelectors(state: Selector<object, IFileUploadState>) {
    this._defaultSelectors = createDefaultFileOperationSelectors(state);
    return this;
  }
  finalize() {
    this._fileActions = populateDefaultFileActions(this._defaultActionMap);
    this._reducer = createFileUploadReducerFromFunctionArray(this._defaultState, this._fileActions);
    return new SafariReduxFileTransferObjectDefinition(this._fileActions, this._reducer, this._defaultSelectors);
  }
}
export const UI_REDUCER = <S, T extends SafariObject>(object: SafariReduxObject<S, T>) => {
  if (!object['isFinalized']) {
    throw new Error(`Object [${object['projectName']}][${object['objectName']}] must be finalized before creating a reducer. Did you forget to call finalize() ?`);
  }

  const array = [];
  if (typeof object['getReducers'] === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- we don't know the type yet
    object['getReducers'](array);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- we don't know the type yet
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- we don't know the type yet
    object.default.reducers(array);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- we don't know the type yet
  return createReducer<S>(object['_defaultState'], ...array);
};

export const DROPDOWN_REDUCER = <T extends IDropdownState>(object: SafariReduxDropdownObject<T>) => {
  if (!object['isFinalized']) {
    throw new Error(`Object [${object['projectName']}][${object['objectName']}] must be finalized before creating a reducer. Did you forget to call finalize() ?`);
  }

  return createDropdownReducerFromFunctionArray<T>(object['_defaultState'], object['_defaultActions'], object['_dropdownType']);
};
export const REDUCER = <S extends ISafariObjectState<T>, T extends SafariObject>(object: SafariReduxApiObject<S, T>, logger: IReducerErrorLogger) => {
  if (!object['isFinalized']) {
    throw new Error(`Object [${object['projectName']}][${object['objectName']}] must be finalized before creating a reducer. Did you forget to call finalize() ?`);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- we dont know reducer types
  return createReducerFromFunctionArray<S>(object['_defaultState'], object['_defaultActions'], object['custom'] ? object['custom']['reducers'] : null, logger);
};

export abstract class SafariReduxObject<S, T extends SafariObject> implements IReduxProvider<S, T> {
  private _isFinalized = false;
  constructor(
    private _projectName: string,
    private _objectName: string
  ) {}

  /**
   *
   * @param state - slice of state for this object. Usually a subselector from getSharedFeatureState
   * @returns self for further function chaining
   */
  // This used to be necessary back in the day when we had custom/default in data objects actions.
  // We don't customize actions or services anymore, we use injectable micro-objects. And UI
  // objects never had a concept of default or custom in the first place. But to remove this we
  // have to update a bunch of code. We'll eventually get to it, just not at this time.
  abstract get default();
  /**
   * Specifies whether finalize() was called
   */
  protected get isFinalized() {
    return this._isFinalized;
  }
  /**
   * Returns name of the project that the object is registered with
   */
  protected get projectName() {
    return this._projectName;
  }
  /**
   * Returns name of the object
   */
  protected get objectName() {
    return this._objectName;
  }
  protected finalize() {
    this._isFinalized = true;
  }
  public toString = (): string => `[${this.projectName}][${this.objectName}]`;

  protected createAction<T1 extends string>(type: T1): ActionCreator<T1, () => Action<T1>>;
  protected createAction<T1 extends string, P extends object>(type: T1, config: ActionCreatorProps<P> & NotAllowedCheck<P>): ActionCreator<T1, (props: P & NotAllowedCheck<P>) => P & Action<T1>>;
  protected createAction<T1 extends string, P extends object = any>(type: T1, creator?: ActionCreatorProps<P> & NotAllowedCheck<P>) {
    const fullType = `[${this.projectName}][${this.objectName}] ${type}`;
    //  console.log('action created', type);
    if (!creator) {
      return createAction(fullType);
    } else {
      return createAction(fullType, creator);
    }
  }

  /**
   * Use this helper function when writing custom reducers. It will find an object in the state array based on either id or actionId
   * and will overwrite it with the payload that is passed in
   * @param array - Array of objects
   * @param payload  - Payload of the object to be modified. Payload must include ID property in order to find the object
   * @param insertIfNotFound - Boolean flag specifying whether the object will be inserted into the state array if it wasn't found. Default - true
   * @param merge - Boolean that specifies whether the object will fully replace the existing object (merge false) or just merge with what's in there already (merge true)
   *                Default - true
   * @returns Array of objects that was passed in, now containing desired object modifications
   */
  protected modifyObjectInArray<T1 extends T & ActionInfoBase>(
    array: T1[],
    payload: ObjectWithActionInfo,
    originalPayload: ObjectWithActionInfo,
    insertIfNotFound = true,
    merge = false,
    isList = false
  ): T1[] {
    return modifyObjectInArray(array, payload, originalPayload, insertIfNotFound, merge, isList);
  }
  /**
   * Use this helper function when writing custom reducers. It will find an object in the state array based on either id or actionId
   * and will remove it from the array
   * @param array - Array of objects
   * @param payload  - Payload of the object to be modified. Payload must include ID property in order to find the object
   * @returns Array of objects that was passed in, with the object removed
   */
  protected removeObjectFromArray<T1 extends T & ActionInfoBase>(array: T1[], payload: ObjectWithActionInfo, originalPayload: ObjectWithActionInfo) {
    return removeObjectFromArray(array, payload, originalPayload);
  }
}

export class UiActionSelectorPair<ActionType, SelectorType> {
  action: ActionType;
  selector: MemoizedSelector<object, SelectorType, DefaultProjectorFn<SelectorType>>;
}

/**
 * @description This is the base class for all UI redux state objects.
 * NOTE: ALL top-level properties must be OBJECTS! No primitives, arrays, etc.
 * In addition to this, top level properties can not contain property 'type'
 *
 * All state properties (payloads) need to be unique to the page redux UI. They are definitions
 * of action messages and they are applicable only to a particular state. Think of it as event wrappers for
 * a specific message that are unique to this state only.
 *
 * DO NOT derive from or directly use things like models from data-access or shared, etc as the top state property.
 * It will work until someone adds property 'type' on one of those models. For this reason
 * always use uniquely defined state payloads which then can contain those models.
 *
 * @example
 * INCORRECT:
 * export class PageUiStateChild extends PageUiState {
 *    hasDrafts: boolean;
 *    garnishmentAccountRequest: {
 *       accountNumber: string;
 *       type: GarnishmentType;
 *       amount: number;
 *    }
 * }
 * CORRECT:
 * export class PageUiStateChild extends PageUiState {
 *    draftInfoPayload: {
 *      hasDrafts: boolean
 *    },
 *    garnishmentAccountRequest: {
 *      accountNumber: string;
 *      accountType: GarnishmentType;
 *      amount: number;
 *    }
 * }
 * OR
 *  * export class PageUiStateChild extends PageUiState {
 *    draftInfoPayload: {
 *      hasDrafts: boolean
 *    },
 *    garnishmentAccountPayload: {
 *         garnishmentAccount: GarnishmentAccount;
 *    }
 * }
 */
export class PageUiState implements Record<string, object> {
  [key: string]: object;
  saveRequest?: SaveRequest | undefined;
  fileDownloadRequest?: FileDownloadRequest | undefined;
  filePreviewRequest?: FilePreviewRequest | undefined;
  filePreviewCloseRequest?: FilePreviewCloseRequest | undefined;
  navigationRequest?: any | undefined; // <-- This one is in common object models so it cant'
  // be redefined to extend from PageUiAction (this project is higher up)
  notificationRequest?: NotificationRequest | undefined;
  // These can be "sticky" in a sense that they can be kept after page reloads itself
  persistent?: any | undefined;
  // These shoudl be "sticky" accross saves, but cleared once the page reloads
  errors?: any | undefined;
}
export enum NotifcationRequestType {
  Info,
  Error,
  Success
}

/**
 * TOASTR CLASSES
 *
 * These classes are a direct ripoff from Toastr's types and classes (with our NotifcationRequest prefix)
 * Didn't want to import the whole toastr module in this project, so I just copied them
 */
export type NotificationRequestProgressAnimationType = 'increasing' | 'decreasing';
export type NotificationRequestDisableTimoutType = boolean | 'timeOut' | 'extendedTimeOut';
export interface NotificationRequestComponentType<T> {
  // eslint-disable-next-line @typescript-eslint/prefer-function-type -- framework level
  new (...args: any[]): T;
}
export interface NotificationRequestConfig<ConfigPayload = any> {
  disableTimeOut?: NotificationRequestDisableTimoutType;
  timeOut?: number;
  closeButton?: boolean;
  extendedTimeOut?: number;
  progressBar?: boolean;
  progressAnimation?: NotificationRequestProgressAnimationType;
  enableHtml?: boolean;
  toastClass?: string;
  positionClass?: string;
  titleClass?: string;
  messageClass?: string;
  easing?: string;
  easeTime?: string | number;
  tapToDismiss?: boolean;
  toastComponent?: NotificationRequestComponentType<any>;
  onActivateTick?: boolean;
  newestOnTop?: boolean;
  payload?: ConfigPayload;
}
/**
 * END TOASTR CLASSES
 */
export class NotificationRequest extends PageUiAction {
  notificationType: NotifcationRequestType;
  message: string;
  title?: string;
  configOverride?: NotificationRequestConfig;
  additionalInfo?: any;
}

export class SaveRequest extends PageUiAction {
  additionalInfo?: any;
}
export const defaultPageUiState: PageUiState = {
  saveRequest: undefined,
  navigationRequest: undefined,
  persistent: {} as any,
  errors: {} as any
};

export abstract class SafariReduxCustomObject<S extends object> extends SafariReduxObject<S, any> {
  private _actionSelectorPairs: UiActionSelectorPair<any, any>[] = [];
  private _defaultState: S = null;

  private _childOns = [];

  constructor(
    projectName: string,
    objectName: string,
    protected _sliceSelector: MemoizedSelector<object, S, DefaultProjectorFn<S>> = null,
    defaultState: S = null,
    private _addedActionNames = []
  ) {
    super(projectName, objectName);
    if (defaultState) {
      this.addState({ ...defaultState }).finalize();
    }
  }

  /**
   * 
   * @param action Name of the action
   * @param projector redux state projector function. Usually a simple return of a particular property from the state. NOTE: Type of the action is derived from the return type of this function
   * @param ons simple object for reducer auto-generation OR reducer function. 
   * @returns action/selector pair for use in this.sendMessage and this.observeMessage UI object methods
   * 
   * 

   * @description This function automatically creates action creator function of type same as return type 
   * of the projector function. Projector function is just a standard re  selector function.
   * Because the return type of projector is used to derive props of the action, the type must be an object
   * and can not have 'type' property (NGRX action constraints)
   * 
   * @example
     
     (1)
     MOST COMMON REQUEST.
     YOU CAN PRETTY MUCH COPY/PASTE THIS FOR MOST CASES AND JUST CHANGE ACTION/PROP NAMES
     THE REDUCER WILL BE AUTOGENERATED AND WILL ALWAYS EXPAND WHATEVER COMES FROM THE ACTION. 
     
     NOTE: REDUCER WILL NEVER ASSIGN NULL EVEN IF THE ACTION IS EMPTY. IT STAMP NULL/EMPTY
     ACTIONS WITH A REQUEST INFO OBJECT. THIS IS TO SUPPORT THE MOST COMMON CASE OF RETRIGGERABLE
     EMPTY ACTIONS (requestLoadHistory, requestAddSubject, confirmReceiptRequest, etc)
    
     IF YOU WANT TO OVERRIDE THIS BEHAVIOR, USE THE 3RD PARAMETER TO PASS YOUR OWN REDUCER FUNCTION

     toggleInvoicePreviewMessage = super.addMessage(
      'Toggle Invoice Preview Message',
      state => state.invoicePreviewInfo,
      { invoicePreviewInfo: this.fromAction() }
    );

     (2)
     LESS COMMON. ACTION THAT ALWAYS ASSIGNS A SPECIFIC HARD-CODED VALUE: 

      clearInvoicePreviewMessage = super.addMessage(
      'Clear Invoice Preview Message',
      state => state.invoicePreviewInfo,
      { invoicePreviewInfo: null }
    );

    (3)
     OTHER/FREE-FORM, DEPENDS ON YOUR STATE AND REQUIREMENTS OF THE ACTION: 
     YOU PASS THE REDUCER FUNCTION YOURSELF AND DO WHATEVER YOU WANT

     openFolder = super.addMessage(
    'Open Folder',
    state => state.persistent.openFolderRequest,
    (state, action) => {
        const array = [...state.persistent.openFolderRequest];
        array[action.attachmentType] = { id: action.id, attachmentType: action.attachmentType };
        return {
          ...state,
          persistent: { ...state.persistent, openFolderRequest: [...array] }
        };
      }
    );

   */
  protected addMessage<T1 extends string, Result extends object>(
    action: string,
    projector: (s1: S) => Result,
    objectToExpand: object | OnReducer2<S, ActionCreator<T1, (props: Result & NotAllowedCheck<Result>) => Result & Action<T1>>[]>
  ) {
    if (this._addedActionNames.find(x => action.toUpperCase() == x.toUpperCase())) {
      throw new Error('Action already exists. Action Name: ' + action);
    }
    const props2 = projector == null ? null : props<ReturnType<typeof projector>, any>();

    const ons = typeof objectToExpand === 'function' ? objectToExpand : this._autoCreateReducer(objectToExpand);

    const result = this._addActionAndSelector({
      action: super.createAction(action, props2 as any) as ActionCreator<T1, (props: ReturnType<typeof projector>) => ReturnType<typeof projector> & Action<T1>>,
      selector: createSelector(this._sliceSelector, projector)
    });
    this._childOns.push(on(result.action as any, ons as any));
    this._addedActionNames.push(action);
    return result;
  }

  private _autoCreateReducer<T1 extends string, Result extends object>(o): OnReducer2<S, ActionCreator<T1, (props: Result & NotAllowedCheck<Result>) => Result & Action<T1>>[]> {
    return (state, action) => {
      const objectToExpand = cloneDeep(o);
      if (typeof o[Object.keys(o)[0]] === 'function') {
        const { params }: { params: FromActionBehavior } = o[Object.keys(o)[0]]();

        if (Object.keys(action).length == 1 && Object.keys(action)[0] == 'type' && params == FromActionBehavior.NoNullExpansion) {
          objectToExpand[Object.keys(objectToExpand)[0]] = null;
        } else {
          objectToExpand[Object.keys(objectToExpand)[0]] = { ...action, ...this.stampRequest() };
        }
      } else {
        objectToExpand[Object.keys(objectToExpand)[0]] = o[Object.keys(o)[0]];
      }

      return {
        ...state,
        ...objectToExpand
      };
    };
  }
  protected fromAction(params: FromActionBehavior = FromActionBehavior.Default) {
    return () => ({ params });
  }
  protected fromNull() {
    return null;
  }
  protected stampRequest() {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- OK
      __requestInfo: { requestDateTime: new Date().toISOString() }
    };
  }
  private _addActionAndSelector<ActionType, SelectorType>(actionSelectorPair: UiActionSelectorPair<ActionType, SelectorType>) {
    /**
     * During transitional phase sendMessage in the base class will need to support both regular
     * actions and our actionselector pairs. But we cant just check typeof because we use literals
     * so we are going to add a strange-looking property that we will check for in the base. Once
     * the conversion is full done we can remove that
     */
    // eslint-disable-next-line ,@typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-argument -- read above
    this._actionSelectorPairs.push({ ...actionSelectorPair, ___action_selector___: true } as any);
    // eslint-disable-next-line @typescript-eslint/naming-convention,  @typescript-eslint/no-unsafe-return -- same as above
    return { ...actionSelectorPair, ___action_selector___: true } as UiActionSelectorPair<ActionType, SelectorType>;
  }
  protected addState(defaultState: S) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- we can't say for sure what the state will look like
    this._defaultState = defaultState;

    return this;
  }
  protected getReducers(ons) {
    this._childOns.forEach(element => {
      ons.push(element);
    });
  }
}
export abstract class SafariReduxPageUiObject<S extends PageUiState> extends SafariReduxCustomObject<S> {
  // TODO: Eventually all these actionandselector calls should eventually be converted to the new addMessage method
  // and have their hand-written reducers removed
  clearState = this['_addActionAndSelector']({
    action: this.createAction('Clear State', props<{ includePersistent: boolean; includeErrors: boolean }>()),
    selector: null
  });
  requestSave = this['_addActionAndSelector']({
    action: this.createAction('Request Save', props<{ saveRequest?: SaveRequest }>()),
    selector: createSelector(this._sliceSelector, (state: PageUiState) => state.saveRequest)
  });

  requestNavigation = this['_addActionAndSelector']({
    action: this.createAction('Request Navigation', props<{ routerNavigationInfo: RouterNavigationInfo }>()),
    selector: createSelector(this._sliceSelector, (state: PageUiState) => state.navigationRequest)
  });

  requestNotification = this['_addActionAndSelector']({
    action: this.createAction('Request Notification', props<{ notificationRequest: NotificationRequest }>()),
    selector: createSelector(this._sliceSelector, (state: PageUiState) => state.notificationRequest)
  });

  requestFileDownload = this.addMessage('Request File Download', state => state.fileDownloadRequest, { fileDownloadRequest: this.fromAction() });
  requestFilePreview = this.addMessage('Request File Preview', state => state.filePreviewRequest, { filePreviewRequest: this.fromAction() });
  requestFilePreviewClose = this.addMessage('Request File Preview Close', state => state.filePreviewCloseRequest, { filePreviewCloseRequest: this.fromAction() });

  constructor(projectName: string, objectName: string, sliceSelector: MemoizedSelector<object, S, DefaultProjectorFn<S>> = null, defaultState: S = null) {
    super(projectName, objectName, sliceSelector, defaultState, ['Clear State', 'Request Save', 'Request Navigation', 'Request Notification']);
  }

  protected getReducers(ons) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- test
    ons.push(
      on(this.clearState.action, (state: PageUiState, action: { includePersistent: boolean; includeErrors: boolean }): PageUiState => {
        if (action.includePersistent && action.includeErrors) {
          return { ...this['_defaultState'], persistent: { ...this['_defaultState'].persistent }, errors: { ...this['_defaultState'].errors } };
        } else {
          const s = { ...this['_defaultState'] };
          if (!action.includePersistent) {
            s.persistent = { ...state.persistent };
          }
          if (!action.includeErrors) {
            s.errors = { ...state.errors };
          }

          return {
            ...s,
            ...this.stampRequest()
          };
        }
      })
    );
    ons.push(
      on(
        this.requestNotification.action,
        (state: PageUiState, action: { notificationRequest: NotificationRequest }): PageUiState => ({
          ...state,
          notificationRequest: { ...action.notificationRequest, ...this.stampRequest() }
        })
      )
    );
    ons.push(
      on(
        this.requestNavigation.action,
        (state: PageUiState, action: { routerNavigationInfo: RouterNavigationInfo }): PageUiState => ({
          ...state,
          navigationRequest: action.routerNavigationInfo as any
        })
      )
    );

    ons.push(
      on(
        this.requestSave.action,
        (state: PageUiState, action: { saveRequest?: SaveRequest }): PageUiState => ({
          ...state,
          saveRequest:
            action == null
              ? ({
                  additionalInfo: null,
                  ...this.stampRequest()
                } as SaveRequest)
              : { ...action.saveRequest, ...this.stampRequest() }
        })
      )
    );
    super.getReducers(ons);
  }
}
export abstract class SafariReduxUiObject<S, T extends SafariObject> extends SafariReduxObject<S, T> {
  private _defaultState: S = null;

  private _actionSelectorPairs: UiActionSelectorPair<any, any>[] = [];
  constructor(
    projectName: string,
    objectName: string,
    protected _sliceSelector: MemoizedSelector<object, S, DefaultProjectorFn<S>> = null
  ) {
    super(projectName, objectName);
  }
  protected addActionAndSelector<ActionType, SelectorType>(actionSelectorPair: UiActionSelectorPair<ActionType, SelectorType>) {
    /**
     *
     * During transitional phase sendMessage in the base class will need to support both regular
     * actions and our actionselector pairs. But we cant just check typeof because we use literals
     * so we are going to add a strange-looking property that we will check for in the base. Once
     * the conversion is full done we can remove that
     */
    // eslint-disable-next-line ,@typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-argument -- read above
    this._actionSelectorPairs.push({ ...actionSelectorPair, ___action_selector___: true } as any);
    // eslint-disable-next-line @typescript-eslint/naming-convention,  @typescript-eslint/no-unsafe-return -- same as above
    return { ...actionSelectorPair, ___action_selector___: true } as UiActionSelectorPair<ActionType, SelectorType>;
  }
  protected addState(defaultState: S) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- we can't say for sure what the state will look like
    this._defaultState = defaultState;

    return this;
  }
}
export class SafariReduxApiObject<S extends ISafariObjectState<T>, T extends SafariObject> extends SafariReduxObject<S, T> {
  private _fileTransferObject: SafariReduxFileTransferObjectDefinition;
  private _defaultActionMap: Map<ObjectActionTypes, string> = new Map();
  private _defaultState: S = null;

  private _additionalReducers: ActionReducer<any, Action> = null;

  private _defaultSelectors: DefaultSelectorConverter<T>;
  private _defaultActions: DefaultActionConverter<T>;
  constructor(
    projectName: string,
    objectName: string,
    private _service: CrudService<T>,
    selectors: Selector<object, ISafariObjectState<T>>,
    // This looks unused but it's not. We're not exposing it but lower level framework functions
    // will access it via indexer
    private _fileTransferService: ObjectFileTransferServiceBase = null
  ) {
    super(projectName, objectName);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- can be anything
    const proto = Object.getPrototypeOf(this._service.adapter);

    if (Object.prototype.hasOwnProperty.call(proto, 'toCreateModel') || Object.prototype.hasOwnProperty.call(proto, 'toUpdateModel') || this._service['_fileTransferService']) {
      this._addCreateOrUpdateObject();
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'toCreateMultipleModel') || Object.prototype.hasOwnProperty.call(proto, 'toUpdateMultipleModel')) {
      this._addCreateOrUpdateMultipleObjects();
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'toUpdateListModel')) {
      this._addUpdateObjectList();
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'fromGetModel')) {
      this._addLoadObject();
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'fromListModel')) {
      this._addLoadObjectList();
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'fromHistory')) {
      this._addLoadObjectHistory();
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'toDeleteModel')) {
      this._addDeleteObject();
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'toDeleteMultipleModel')) {
      this._addDeleteMultipleObject();
    }
    if (Object.prototype.hasOwnProperty.call(proto, 'toUpdatePartialModel')) {
      this._addUpdatePartialObject();
    }

    if (_fileTransferService) {
      this._fileTransferObject = _fileTransferService._fileTransferObject;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- can be anything
      const protoFile = Object.getPrototypeOf(_fileTransferService);
      if (Object.prototype.hasOwnProperty.call(protoFile, 'getUploadUrl') || Object.prototype.hasOwnProperty.call(protoFile, 'getUpdateUrl')) {
        this._addUploadObjectFile();
      }
      if (Object.prototype.hasOwnProperty.call(protoFile, 'getRemoveUrl')) {
        this._addRemoveObjectFile();
      }

      if (Object.prototype.hasOwnProperty.call(protoFile, 'getMoveMultipleFilesUrl')) {
        this._addMoveObjectMultipleFiles();
      }
      if (Object.prototype.hasOwnProperty.call(protoFile, 'getRemoveMultipleFilesUrl')) {
        this._addRemoveObjectMultipleFiles();
      }
    }

    this._addState({ ...reduxObjectInitState })
      ._addSelectors(selectors)
      ._finalize();
  }

  /**
   * Adds actions needed load a single object (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  private _addLoadObject() {
    const actionName = `[${this.projectName}] Load ${this.objectName}`;
    this._defaultActionMap.set(ObjectActionTypes.LoadObject, actionName);
    return this;
  }
  /**
   * Adds actions needed to create a new or update an existing object (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  private _addCreateOrUpdateObject() {
    const actionName = `[${this.projectName}] CreateOrUpdate ${this.objectName}`;
    this._defaultActionMap.set(ObjectActionTypes.CreateOrUpdateObject, actionName);
    return this;
  }
  private _addCreateOrUpdateMultipleObjects() {
    const actionName = `[${this.projectName}] CreateOrUpdateMultiple ${this.objectName}`;
    this._defaultActionMap.set(ObjectActionTypes.CreateOrUpdateMultipleObjects, actionName);
    return this;
  }
  /**
   * Adds actions needed to patch (partially update) an object
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  private _addUpdatePartialObject() {
    const actionName = `[${this.projectName}] Update Partial ${this.objectName}`;
    this._defaultActionMap.set(ObjectActionTypes.UpdatePartialObject, actionName);
    return this;
  }
  /**
   * Adds actions needed to delete the object (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  private _addDeleteObject() {
    const actionName = `[${this.projectName}] Delete ${this.objectName}`;
    this._defaultActionMap.set(ObjectActionTypes.DeleteObject, actionName);
    return this;
  }
  private _addDeleteMultipleObject() {
    const actionName = `[${this.projectName}] Delete Multiple ${this.objectName}`;
    this._defaultActionMap.set(ObjectActionTypes.DeleteMultipleObjects, actionName);
    return this;
  }
  /**
   * Adds actions needed to load object history (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  private _addLoadObjectHistory() {
    const actionName = `[${this.projectName}] Load ${this.objectName} History`;
    this._defaultActionMap.set(ObjectActionTypes.LoadObjectHistory, actionName);
    return this;
  }
  /**
   * Adds actions needed to begin uploading a file from the object (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  private _addUploadObjectFile() {
    const actionName = `[${this.projectName}] Upload ${this.objectName} File`;
    this._defaultActionMap.set(ObjectActionTypes.UploadFile, actionName);
    return this;
  }
  /**
   * Adds actions needed to begin removing a file from the object (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  private _addRemoveObjectFile() {
    const actionName = `[${this.projectName}] Remove ${this.objectName} File`;
    this._defaultActionMap.set(ObjectActionTypes.RemoveFile, actionName);
    return this;
  }
  /**
   * Adds actions needed to begin removing a file from the object (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  private _addGenerateZipFile() {
    const actionName = `[${this.projectName}] Generate ${this.objectName} Zip File`;
    this._defaultActionMap.set(ObjectActionTypes.GenerateZipFileLink, actionName);
    return this;
  }
  /**
   * Adds actions needed to begin removing multiple files from the object (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  private _addRemoveObjectMultipleFiles() {
    const actionName = `[${this.projectName}] Remove ${this.objectName} Multiple Files`;
    this._defaultActionMap.set(ObjectActionTypes.RemoveMultipleFiles, actionName);
    return this;
  }
  /**
   * Adds actions needed to begin moving  multiple files from the object (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   *
   */
  private _addMoveObjectMultipleFiles() {
    const actionName = `[${this.projectName}] Move ${this.objectName} Multiple Files`;
    this._defaultActionMap.set(ObjectActionTypes.MoveMultipleFiles, actionName);
    return this;
  }
  /**
   * Adds actions needed to retrieve lists of objects (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   *
   */
  private _addLoadObjectList() {
    const actionName = `[${this.projectName}] Load ${this.objectName} List`;
    this._defaultActionMap.set(ObjectActionTypes.LoadObjectList, actionName);
    return this;
  }
  /**
   * Adds actions needed to update lists of objects (main action, success and fail)
   *
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  private _addUpdateObjectList(): this {
    const actionName = `[${this.projectName}] Update ${this.objectName} List`;
    this._defaultActionMap.set(ObjectActionTypes.UpdateObjectList, actionName);
    return this;
  }
  /** 
   * @param defaultState The state that this object will be initialized with. Usually just an expansion of {...reduxObjectInitState} (or leave blank)
   * @param additionalReducers Anything extra you might be adding to this format such as
   * customReducers => {
        customReducers.push(
          on(this.custom.actions.myCustomAction1, (state: any, action: any) => {
            // Do something
            return { ...state, something };
          }),
          on(this.custom.actions.myCustomAction2, (state: any, action: any) => ({
          })
        );
          etc
      @returns self for further function chaining
 
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   * 
   */
  private _addState(defaultState: any = { ...reduxObjectInitState }) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- we can't say for sure what the state will look like
    this._defaultState = defaultState;
    return this;
  }
  /**
   *
   * @param state - slice of state for this object. Usually a subselector from getSharedFeatureState
   * @returns self for further function chaining
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   *
   */
  private _addSelectors(state: Selector<object, ISafariObjectState<T>>) {
    this._defaultSelectors = createDefaultSelectors<T>(state);
    return this;
  }
  /**
   * *  You must call this before this object will become valid for either reducer or effects
   */
  private _finalize() {
    let actionName = `[${this.projectName}] Clear ${this.objectName} Error`;
    this._defaultActionMap.set(ObjectActionTypes.ClearObjectError, actionName);
    actionName = `[${this.projectName}] Swap Current/Refreshed ${this.objectName}`;
    this._defaultActionMap.set(ObjectActionTypes.SwapCurrentWithRefreshed, actionName);
    actionName = `[${this.projectName}] Swap Current/Refreshed ${this.objectName}List`;
    this._defaultActionMap.set(ObjectActionTypes.SwapCurrentListWithRefreshed, actionName);

    actionName = `[${this.projectName}] Clear ${this.objectName} State`;
    this._defaultActionMap.set(ObjectActionTypes.ClearState, actionName);
    this._defaultActions = populateDefaultActions(this._defaultActionMap);
    super.finalize();
  }

  /**
   * Gets default actions and selectors
   * Don't even call this unless you have a very good reason. You should always use
   * LoadObject/LoadList, etc from the base page
   * This will eventually be removed
   */
  get default() {
    return {
      actions: this._defaultActions,
      selectors: this._defaultSelectors
    };
  }
  /**
   * Returns the service that communicates with the API
   * This one is used by low-level effect by indexing it via ['service']
   * Nobody else should be using this (currently there is old matter save effect that will be gone
   * soon, hopefully and then some report effect that also needs to be fixed)
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  protected get service(): CrudService<T> {
    return this._service;
  }
  /**
   * Similar to crud service. Used via indexer in some low level framework files but should
   * not be visible to a random page
   *
   * NOTE: Be careful if renaming. It might be used via indexer [''] in lower-level framework files
   *
   */
  protected get fileTransferObject(): SafariReduxFileTransferObjectDefinition {
    return this._fileTransferObject;
  }
  // Sometimes the client pages may need an endpoint for some reason. It is usually used
  // for anonymous access endpoints like files, logos, etc, but we'll expose both
  // the default and any additional endpoints. Don't see a whole lot of harm in page being able
  // to see the endpoint. It can't do anything with it anyway
  get endpoint() {
    return this._service.endpoint;
  }
  get otherEndpoints() {
    return this._service.otherEndpoints;
  }
}

export interface IDropdownServiceProvider {
  get service(): DropdownService;
}
export interface IReduxProvider<S, T extends SafariObject> {
  // TODO: rename this to actionsSelectors and make it protected
  // will require some refactor on the effects, etc
  // But ultimately we don't want pages to see it in intellisense
  // when someone types this.LpmsObject.Something.

  get default(): { actions: DefaultActionConverter<T>; selectors: DefaultSelectorConverter<T>; reducers?: ActionReducer<S, Action> };

  toString(): string;
}

export interface IReduxDropdownProvider {
  get default(): { actions: DefaultDropdownActionConverter; selectors: DefaultDropdownSelectorConverter };
}
export class SafariReduxDropdownObject<T extends IDropdownState> extends SafariReduxObject<any, IdName> implements IDropdownServiceProvider {
  private _defaultActionMap: Map<DropdownActionTypes, string> = new Map();
  private _defaultState: T = null;
  private _defaultActions: DefaultDropdownActionConverter;
  private _defaultSelectors: DefaultDropdownSelectorConverter = null;
  private _dropdownType: { [key: string]: SafariObjectId; [value: number]: SafariObjectId };
  constructor(
    projectName: string,
    private _service: DropdownService,
    selectors: Selector<object, IDropdownState> = null,
    dropdownType = null,
    initState = null
  ) {
    super(projectName, 'Dropdown');
    if (selectors) {
      this.addAllActions()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- we dont know what it is
        .addSelectors(selectors, dropdownType)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- we dont know what it is
        .addState(initState ? { ...initState } : ({} as any))
        .finalize();
    }
  }

  addClearDropdown() {
    this._defaultActionMap.set(DropdownActionTypes.ClearDropdown, `[${this.projectName}][${this.objectName}]: Clear`);
    return this;
  }
  addLoadDropdown() {
    this._defaultActionMap.set(DropdownActionTypes.LoadDropdown, `[${this.projectName}][${this.objectName}]: Load`);
    this._defaultActionMap.set(DropdownActionTypes.LoadDropdownSuccess, `[${this.projectName}][${this.objectName}]: Load Success`);
    this._defaultActionMap.set(DropdownActionTypes.LoadDropdownFail, `[${this.projectName}][${this.objectName}]: Load Fail`);

    this._defaultActionMap.set(DropdownActionTypes.LoadBulkDropdown, `[${this.projectName}][${this.objectName}]: Load Bulk`);
    this._defaultActionMap.set(DropdownActionTypes.LoadBulkDropdownSuccess, `[${this.projectName}][${this.objectName}]: Load Bulk Success`);
    this._defaultActionMap.set(DropdownActionTypes.LoadBulkDropdownFail, `[${this.projectName}][${this.objectName}]: Load Bulk Fail`);

    return this;
  }
  addAllActions() {
    this.addClearDropdown();
    this.addLoadDropdown();
    return this;
  }
  addState(defaultState: T) {
    this._defaultState = defaultState;
    return this;
  }
  addSelectors(state: Selector<object, IDropdownState>, dropdownType: { [key: string]: SafariObjectId; [value: number]: SafariObjectId }) {
    this._dropdownType = dropdownType;
    this._defaultSelectors = createGetDropdownStateSelector(state, dropdownType);
    return this;
  }

  finalize() {
    this._defaultActions = populateDefaultDropdownActions(this._defaultActionMap);
    super.finalize();
  }

  get dropdownType() {
    return this._dropdownType;
  }

  get default() {
    return {
      actions: this._defaultActions,
      selectors: this._defaultSelectors
    };
  }

  /**
   * Returns the service that communicates with the API
   */
  get service(): DropdownService {
    return this._service;
  }
}

export const safariReduxSearchGenerator = <T>(projectName: string) => new SafariReduxSearchGenerator<T>(projectName);

export class SafariReduxSearchDefinition<T> {
  constructor(
    private _sarchActions: DefaultSearchActionConverter,
    private _reducer: ActionReducer<T, Action>,
    private _searchSelectors: DefaultSearchSelectorConverter
  ) {}

  get reducer() {
    return this._reducer;
  }

  get default() {
    return {
      actions: this._sarchActions,
      selectors: this._searchSelectors
    };
  }
}

class SafariReduxSearchGenerator<T extends ISearchState> {
  private _defaultActionMap: Map<SearchActionTypes, string> = new Map();
  private _reducer: ActionReducer<T, Action> = null;
  private _defaultState: T = null;
  private _searchSelectors: DefaultSearchSelectorConverter = null;
  private _searchActions: DefaultSearchActionConverter;
  constructor(private _projectName: string) {}

  addClearSearch() {
    this._defaultActionMap.set(SearchActionTypes.ClearSearch, `[${this._projectName}] Search: Clear`);
    return this;
  }
  addLoadSearch() {
    this._defaultActionMap.set(SearchActionTypes.LoadSearch, `[${this._projectName}] Search: Load`);
    this._defaultActionMap.set(SearchActionTypes.LoadSearchSuccess, `[${this._projectName}] Search: Load Success`);
    this._defaultActionMap.set(SearchActionTypes.LoadSearchFail, `[${this._projectName}] Search: Load Fail`);

    return this;
  }
  addAllActions() {
    this.addClearSearch();
    this.addLoadSearch();
    return this;
  }
  addState(defaultState: T) {
    this._defaultState = defaultState;
    return this;
  }
  addSelectors(state: Selector<object, ISearchState>) {
    this._searchSelectors = createGetSearchStateSelector(state);
    return this;
  }

  finalize() {
    this._searchActions = populateDefaultSearchActions(this._defaultActionMap);
    this._reducer = createSearchReducerFromFunctionArray<T>(this._defaultState, this._searchActions);
    return new SafariReduxSearchDefinition<T>(this._searchActions, this._reducer, this._searchSelectors);
  }
}
